<template>
  <div>
    <div id="my_dataviz" />
  </div>
</template>
<script>
import * as d3 from 'd3';
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      xAxisLabels: [],
    };
  },
  mounted() {
    const dataset = this.formatData(this.data);
    const labels = this.xAxisLabels;
    const margin = { top: 20, right: 30, bottom: 40, left: 150 };
    const width = 500 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const svg = d3
      .select('#my_dataviz')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Add X axis
    const x = d3
      .scaleLinear()
      .domain([0, d3.max(dataset)])
      .range([0, width]);
    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'translate(-10,0)rotate(-45)')
      .style('text-anchor', 'end')
      .style('font-size', '12px');

    // Y axis
    const y = d3
      .scaleBand()
      .range([0, height])
      .domain(
        labels.map(function(d) {
          return d;
        }),
      )
      .padding(0.1);
    svg.append('g').call(d3.axisLeft(y)).selectAll('text').style('font-size', '15px');

    //Bars
    svg
      .selectAll('myRect')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('x', x(0))
      .attr('y', function(d) {
        return y(d.group);
      })
      .attr('width', function(d) {
        return x(d.headcount);
      })
      .attr('height', y.bandwidth())
      .attr('fill', 'lightblue');
  },
  methods: {
    formatData(data) {
      const tmp = [];
      for (const set in data) {
        tmp.push(data[set].headcount);
        this.xAxisLabels.push(data[set].group);
      }
      return tmp;
    },
  },
};
</script>
<style scoped></style>
