<template>
  <div class="mapContainer">
    <div :ref="mapType" />
  </div>
</template>

<script>
import * as d3 from 'd3';
export default {
  props: {
    mapType: {
      type: String,
      required: true,
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.setupMap(this.$refs[this.mapType], this.mapType);
  },
  methods: {
    addUsStates(
      svg,
      width,
      height,
      reportData,
      projection,
      colorScale,
      mapType,
    ) {
      const path = d3.geoPath().projection(projection);

      // Initialize tooltip
      const tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

      svg
        .append('g')
        .attr('class', 'states')
        .attr('transform', 'translate(50,0)')
        .selectAll('path')
        .data(reportData.states.features)
        .enter()
        .append('path')
        .attr('d', path)
        .style('fill', function (d) {
          if (mapType === 'usMap') {
            const found = reportData.visitsByStateOfResidence.find((state) => state.state === d.properties.STATE_ABBR);
            return found
              ? colorScale(Math.pow(found.sum, 0.5))
              : 'rgba(230, 230, 255, 1)';
          }
          return 'rgb(231, 237, 222)';
        })
        .attr('stroke', function () {
          return mapType === 'usMap' ? 'white' : 'black';
        })
        .attr('stroke-width', 1);
    },
    setupMap(containerRef, mapType) {
      const container = d3.select(containerRef);
      const width = container.node().getBoundingClientRect().width;
      const height = container.node().getBoundingClientRect().height;
      const reportData = this.reportData.reportData;

      const lowColor = d3.rgb(230, 230, 255);
      const highColor = d3.rgb(0, 0, 255);

      const customInterpolator = (t) => {
        return d3.rgb(
          lowColor.r + (highColor.r - lowColor.r) * t,
          lowColor.g + (highColor.g - lowColor.g) * t,
          lowColor.b + (highColor.b - lowColor.b) * t,
        );
      };

      const colorScale = d3
        .scaleSequential(customInterpolator)
        .domain([0, Math.pow(300, 0.75)]);

      const projection = d3
        .geoAlbersUsa()
        .fitSize([width, height], reportData.states);

      const svg = d3
        .select(containerRef)
        .append('svg')
        .attr('width', width)
        .attr('height', height);

      if (mapType === 'usMap') {
        this.addUsStates(
          svg,
          width,
          height,
          reportData,
          projection,
          colorScale,
          'usMap',
        );
        const titleText = 'Visits by State of Residence';
        const words = titleText.split(' ');
        let line = [];
        const lineHeight = 1.2; // ems
        let y = 20;

        const text = svg
          .append('text')
          .attr('x', 0)
          .attr('y', y)
          .attr('font-family', 'Arial')
          .attr('font-size', '24px')
          .attr('fill', 'black');

        words.forEach(function (word, i) {
          line.push(word);
          const joinedLine = line.join(' ');
          if (joinedLine === 'Visits By State of') {
            text.append('tspan').attr('x', 0).attr('y', y).text(joinedLine);
            line = [];
            y += lineHeight * 24; // increment y (24 is the font-size)
          }
        });

        text.append('tspan').attr('x', 0).attr('y', y).text(line.join(' '));
      } else if (mapType === 'longLatMap') {
        this.addUsStates(
          svg,
          width,
          height,
          reportData,
          projection,
          colorScale,
        );

        const visitorsHouseholds = reportData.visitorsHouseholds;

        const filteredVisitorsHouseholds = visitorsHouseholds.filter((d) => d.sum <= 100000);

        const maxValueForScale = 10000;

        const cappedValues = filteredVisitorsHouseholds.map((d) => ({
          ...d,
          sum: Math.min(d.sum, maxValueForScale),
        }));

        const radiusScale = d3
          .scaleSqrt()
          .domain([0, d3.max(cappedValues, (d) => d.sum)])
          .range([2, 10]);

        const titleText = 'Visitors Household';
        const words = titleText.split(' ');
        let line = [];
        const lineHeight = 1.2; // ems
        let y = 20;

        const text = svg
          .append('text')
          .attr('x', 0)
          .attr('y', y)
          .attr('font-family', 'Arial')
          .attr('font-size', '24px')
          .attr('fill', 'black');

        words.forEach(function (word, i) {
          line.push(word);
          const joinedLine = line.join(' ');
          if (joinedLine === 'Visitors') {
            text.append('tspan').attr('x', 0).attr('y', y).text(joinedLine);
            line = [];
            y += lineHeight * 24;
          }
        });

        text.append('tspan').attr('x', 0).attr('y', y).text(line.join(' '));

        svg
          .append('g')
          .attr('class', 'points')
          .attr('transform', 'translate(50,0)')
          .selectAll('circle')
          .data(visitorsHouseholds)
          .enter()
          .append('circle')
          .attr('cx', (d) => projection([d.longitude, d.latitude])?.[0])
          .attr('cy', (d) => projection([d.longitude, d.latitude])?.[1])
          .attr('r', (d) => radiusScale(d.sum))
          .attr('fill', 'rgb(230, 82, 45)');
      }
    },
  },
};
</script>

<style scope>
.mapContainer, .mapContainer div {
  height: 100%;
  max-height: 100%;
  width: 100%;
}
</style>
