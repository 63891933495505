// BarChart.vue
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      default: 'Visits By Hour',
    },
  },
  mounted() {
    // Mapping numbers to day names
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Sort chartData by date_hour (day of the week)
    const sortedChartData = [...this.chartData].sort((a, b) => a.date_hour - b.date_hour);

    // Extract labels and data
    const labels = sortedChartData.map(item => dayNames[item.date_hour - 1]);
    const data = sortedChartData.map(item => parseInt(item.sum, 10));

    // Find the index of the highest value in the data array
    const maxValueIndex = data.indexOf(Math.max(...data));

    // Set the background colors for each bar
    const backgroundColors = data.map((_, index) => {
      if (index === maxValueIndex) {
        return '#4CAF50'; // Green color for the highest bar
      }
      return '#6899D1'; // Blue color for other bars
    });

    this.renderChart(
      {
        labels,
        datasets: [
          {
            label: this.chartTitle,
            data,
            backgroundColor: backgroundColors,
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: this.chartTitle,
          fontSize: 24,
          fontStyle: 'bold',
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontSize: 16,
            usePointStyle: true,
            boxWidth: 5,
            padding: 20,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                beginAtZero: false,
                autoSkip: true,
                maxTicksLimit: 5,
                callback: function (value, index, values) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
        type: this.horizontal ? 'horizontalBar' : 'bar',
      },
    );
  },
};
