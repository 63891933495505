<template>
  <div class="wrapper">
    <div class="outer-container">
      <div class="header">
        <h1>Audience Political Profile</h1>
        <p v-if=" reportData.reportconfigs.politicalDescription ">
          <span><h4>{{ reportData.reportconfigs.politicalDescription }}</h4></span>
        </p>
      </div>
      <div class="main-container">
        <!-- First Row with 2 Columns -->
        <el-row :gutter="20">
          <!-- Column 1 -->
          <el-col
            :span="14"
            :xs="24"
          >
            <div
              class="box"
            >
              <div class="header">
                <div class="chart-title">
                  Political Party
                </div>
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'politicalPartyProbability'"
              />
            </div>
          </el-col>
          <el-col
            :span="10"
            :xs="24"
          >
            <div
              class="box"
            >
              <div class="chart-title">
                Presidential Primary Turnout Probability
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'presidentialPrimaryTurnoutProbability'"
              />
            </div>
          </el-col>
        </el-row>

        <!-- Second Row with 2 Columns -->
        <el-row :gutter="20">
          <!-- Column 1 -->
          <el-col
            :span="14"
            :xs="24"
          >
            <div class="box">
              <div class="chart-title">
                Donation Amount Percentile
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'donationAmountPercentile'"
              />
            </div>
          </el-col>
          <el-col
            :span="10"
            :xs="24"
          >
            <div class="box">
              <div class="chart-title">
                Midterm Primary Turnout Probability
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'midtermPrimaryTurnoutProbability'"
              />
            </div>
          </el-col>
        </el-row>

        <el-footer class="footer">
          <div class="footer-content">
            <img
              :src="logoURL"
              alt="Logo"
              class="logo"
              @error="setDefaultImage"
            >
          </div>
        </el-footer>
      </div>
    </div>
  </div>
</template>

<script>
import PoliticalProbabilityDoughnut from '../graphs/PoliticalProbabilityDoughnut.js';
import PredictionBarChart from '../graphs/PredictionBarChart.js';
import FederalStateDonationBarChart from '../graphs/FederalStateDonationBarChart.js';
import ChartWithModal from '../customComponents/ChartWithModal.vue';

export default {
  components: {
    PoliticalProbabilityDoughnut,
    PredictionBarChart,
    FederalStateDonationBarChart,
    ChartWithModal,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groupColors: {
        '0%-10%': '#FF6384',
        '11%-20%': '#36A2EB',
        '21%-30%': '#FFCE56',
        '31%-40%': '#4BC0C0',
        '41%-50%': '#9966FF',
        '51%-60%': '#FF9F40',
        '61%-70%': '#C0C0C0',
        '71%-80%': '#FFA07A',
        '81%-90%': '#8A2BE2',
        '91%-100%': '#DEB887',
      },
    };
  },
  computed: {
    logoURL() {
      return this.reportData?.logoURL ?? '/images/onspot-logo.svg';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>
<style scoped>
.wrapper {
  height: 100%;
}
.outer-container {
  width: 100%;
  display: flex; /* Added display flex */
  flex-direction: column; /* Stack children vertically */
  height: 100%;
}

.header {
  background-color: white;
  padding: 0;
  margin-bottom: 0;
  height: 10%;
}

.header h1 {
  margin: 0
}

.chart-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.main-container {
  background-color: lightgrey;
  display: flex; /* Make the main container a flex container */
  flex-direction: column;
  height: 90%;
  max-height: 90%;
  gap: 12px !important;
  padding: 12px !important;
  justify-content: space-between;
  border-radius: 8px;
}

.chart-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 5px;
}

.legend-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-label {
  font-size: 14px;
}

.el-row {
  height: 44% !important;
  display: flex;
  gap: 12px;
}

.el-col {
  height: 100% !important;
  padding: 0 !important;
}

.box {
  background-color: white;
  /* margin: 12px; */
  flex: 1; /* Make boxes flex items with flex-grow */
  display: flex; /* Make each .box a flex container */
  flex-direction: column; /* Stack children of .box vertically */
  justify-content: space-between; /* Distribute space between header and footer */
  height: 90%;
  border-radius: 8px;
  gap: 12px;
  padding: 12px;
}

.box .header {
  height: 10%
}

.box .chart-title {
  margin: 0;
  height: 10%;
}

.custom-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 15%;
}

.chart-container {
  flex: 0 0 auto; /* Do not grow or shrink */
  width: calc(100% / 4 - 20px); /* Adjust this to change the number of charts per row */
  height: 60%;
}

@media (max-width: 768px) {
    .footer-text {
      font-size: 16px;
    }
}

@media screen and (max-height: 768px) {
  .box .chart-title {
    font-size: 16px;
  }
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 10% !important;
}

.footer-content {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100px;
  max-height: 50px;
  height: auto;
  position: absolute;
  right: 45px;
}

.footer-text {
  margin: 0;
  color: gray;
  font-weight: bold;
  font-size: 20px;
}

.charts-container {
  display: flex;
  justify-content: center; /* Center-align charts */
  flex-wrap: wrap; /* Allow charts to wrap if needed */
  width: 100%; /* Full width */
  height: 50%;
}

.chart-container {
  flex: 0 0 auto; /* Do not grow or shrink */
  width: calc(
    100% / 4 - 20px
  ); /* Adjust this to change the number of charts per row */
  height: 60%;
}

@media (max-width: 768px) {
  .chart-container {
    width: calc(100% / 2); /* Adjust to display 2 charts per row */
  }

  .footer-text {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .chart-container {
    width: 100%; /* Each chart takes full width on smaller screens */
  }
}
</style>
