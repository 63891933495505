<template>
  <div>
    <svg
      ref="svg"
      height="300"
      width="500"
    >
      <path
        v-for="state in stateData"
        :key="state.feature.id"
        :d="pathGenerator(state.feature)"
        :style="{
          fill: state.color,
          stroke: 'black',
        }"
      />
    </svg>
  </div>
</template>
<script>
import us from './ustopology.json';

const topojson = require('topojson');

const d3 = require('d3');

export default {
  props: {
    statesSum: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      us: us,
      stateVisits: this.statesSum,
      stateANSI: {
        1: 'AL',
        2: 'AK',
        4: 'AZ',
        5: 'AR',
        6: 'CA',
        8: 'CO',
        9: 'CT',
        10: 'DE',
        11: 'DC',
        12: 'FL',
        13: 'GA',
        15: 'HI',
        16: 'ID',
        17: 'IL',
        18: 'IN',
        19: 'IA',
        20: 'KS',
        21: 'KY',
        22: 'LA',
        23: 'ME',
        24: 'MD',
        25: 'MA',
        26: 'MI',
        27: 'MN',
        28: 'MS',
        29: 'MO',
        30: 'MT',
        31: 'NE',
        32: 'NV',
        33: 'NH',
        34: 'NJ',
        35: 'NM',
        36: 'NY',
        37: 'NC',
        38: 'ND',
        39: 'OH',
        40: 'OK',
        41: 'OR',
        42: 'PA',
        44: 'RI',
        45: 'SC',
        46: 'SD',
        47: 'TN',
        48: 'TX',
        49: 'UT',
        50: 'VT',
        51: 'VA',
        53: 'WA',
        54: 'WV',
        55: 'WI',
        56: 'WY',
        60: 'AS',
        66: 'GU',
        69: 'MP',
        72: 'PR',
        74: 'UM',
        78: 'VI',
      },
    };
  },
  computed: {
    projection() {
      return d3
        .geoAlbersUsa()
        .scale(500)
        .translate([250, 150]);
    },

    // Function for converting GPS coordinates into path coordinates
    pathGenerator() {
      return d3.geoPath().projection(this.projection);
    },

    stateColor() {
      return d3
        .scaleSequential()
        .domain([0, 35])
        .interpolator(d3.interpolateBlues);
    },

    stateData() {
      return us
        ? topojson.feature(this.us, this.us.objects.states).features.map(feature => {
          const state = this.stateVisits.find(
            state => state.state === this.stateANSI[feature.id],
          );
          if (!state) {
            return {
              feature,
              color: this.stateColor(1),
            };
          };
          return {
            feature,
            color: this.stateColor(state.sum),
          };
        })
        : [];
    },
  },
  methods: {},
};
</script>
<style scoped></style>
