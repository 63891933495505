<template>
  <div class="flex-container">
    <div class="numbers card-style flex-item">
      <titled-number-chart
        :title="data.response_uniques.label"
        :sum="data.response_uniques.sum"
        :tooltip="data.response_uniques.tooltip"
        :tooltip-pos-top="0"
        :breakpoint="breakpoint"
      />
    </div>
    <div class="numbers card-style flex-item">
      <titled-number-chart
        :tooltip-pos-left="breakpoint ? 100 : 100"
        :title="data.total_visits.label"
        :sum="data.total_visits.sum"
        :tooltip="data.total_visits.tooltip"
        :breakpoint="breakpoint"
      />
    </div>
    <div
      class="stackedbar card-style flex-item three"
      style="padding-bottom: 40px"
      @mouseleave="stackHover = false"
      @mouseenter="stackHover = true"
    >
      <div class="titleChartStacked">
        <div
          id="title"
          style="width: 20em; display: flex; margin-left: 10px"
        >
          {{ data.visits_by_weekday.label }}
          <el-tooltip
            :content="data.visits_by_weekday.tooltip"
            :popper-options="{ width: '300px' }"
          >
            <i
              v-if="!stackHover"
              class="el-icon-info topRight"
              style="opacity: 0; font-size: 18px"
            />
            <i
              v-if="stackHover"
              class="el-icon-info topRight"
              style="font-size: 18px"
            />
          </el-tooltip>
        </div>
      </div>
      <stacked-bar
        v-if="data.visits_by_weekday.catagories"
        :series="data.visits_by_weekday.series"
        :categories="data.visits_by_weekday.catagories"
        :tooltip="data.visits_by_weekday.tooltip"
        :title="data.visits_by_weekday.label"
        :tooltip-pos-left="100"
        :tooltip-pos-top="-11"
        :breakpoint="breakpoint"
      />
    </div>
    <div class="responsesByAudience inner card-style">
      <table-chart
        :headers="[
          {
            label: 'Name',
            col: 'Name',
            col_size: '75%',
            font_size: '12px',
          },
          {
            label: 'Responses',
            col: 'Responses',
            col_size: '10%',
            text_align: 'right',
          },
        ]"
        :series="data.responses_by_audience.series"
        :title="data.responses_by_audience.label"
        :page-size="7"
        :tooltip="data.responses_by_audience.tooltip"
        :apply-heated-col="false"
      />
    </div>
    <div class="responsesByCity card-style two">
      <table-chart
        :headers="[
          { label: 'City', col: 'city' },
          { label: 'State', col: 'state' },
          { label: 'Responses', col: 'sum', text_align: 'right' },
        ]"
        :series="data.responses_by_city.series"
        :title="data.responses_by_city.label"
        :tooltip="data.responses_by_city.tooltip"
        :page-size="8"
        :apply-heated-col="true"
      />
    </div>
    <div
      class="card-style responsesByZip two"
      style="width: 100%;"
    >
      <table-chart
        :headers="[
          { label: 'Zipcode', col: 'zipcode', col_size: '50%' },
          { label: 'Responses', col: 'sum', text_align: 'right' },
        ]"
        :series="data.responses_by_zipcode.series"
        :title="data.responses_by_zipcode.label"
        :tooltip="data.responses_by_zipcode.tooltip"
        :page-size="8"
      />
    </div>
    <div class="break" />
    <div
      v-if="data.responses_by_audience_destination.series.length > 0"
      class="responsesByAudienceDestinations inner card-style "
    >
      <table-chart
        :headers="[
          {
            label: 'Name',
            col: 'Name',
            col_size: '75%',
            font_size: '12px',
          },
          {
            label: 'Responses',
            col: 'Responses',
            col_size: '10%',
            text_align: 'right',
          },
        ]"
        :series="data.responses_by_audience_destination.series"
        :title="data.responses_by_audience_destination.label"
        :page-size="7"
        :tooltip="data.responses_by_audience_destination.tooltip"
        :apply-heated-col="false"
      />
    </div>
    <div class="mapCss break card-style three">
      <map-chart
        :series="data.visits_by_household.series"
        title="Visits by Household"
        :tooltip="data.visits_by_household.tooltip"
      />
    </div>
  </div>
</template>

<script>
import TitledNumberChart from '../charts/TitledNumber.vue';
import StackedBar from '../charts/StackedBar.vue';
import TableChart from '../charts/TableChart.vue';
import MapChart from '../charts/MapChart.vue';

export default {
  name: 'AttributionFootfallDashboard',
  components: {
    TitledNumberChart,
    StackedBar,
    MapChart,
    TableChart,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      stackHover: false,
    };
  },
  computed: {
    breakpoint() {
      return this.windowWidth >= 768;
    },
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style>

.flex-container {
  display: flex;
  flex-wrap: wrap;                    /*  allow items to wrap  */
  justify-content: stretch;     /*  vertical  */
  align-items: stretch;               /*  horizontal, is default
                                          and can be omitted  */
}

.flex-item {
  flex-basis: 23%;                    /*  give each item a width  */
  background-color: #ccc;
  padding: 10px;
  box-sizing: border-box;
}

.two {
  flex: 2 1 0;
}

.three {
  flex: 3 1 0;
}

.topRight::hover {
  color: #509ee3;
}

.break {
  width: 100%;
}

.card-style {
  text-align: center;
  margin-top: 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
  border: 1px solid #f0f0f0;
  border-top-color: rgb(240, 240, 240);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(240, 240, 240);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(240, 240, 240);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(240, 240, 240);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.numbers {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  height: 15vw;
  margin-left: 10px;
  width: 20vw;
}

.stackedbar {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  height: 15vw;
  width: 40vw;
  min-width: 470px;
}

.mapCss {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 25vw;
  min-height: 450px;
  min-width: 300px;
}

.responsesByCity {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 25vw;
  min-width: 270px;
}

.responsesByZip {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 30vw;
}

.responsesByAudience {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 30vw;
}
.responsesByAudienceDestinations {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 30vw;
}

.titleChartStacked {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 900;
  fill: rgb(55, 61, 63);
  margin-right: 80%;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .flex-container {
    display: grid;
    width: 100%;
  }

  .responsesByZip {
    min-height: 650px;
    width: 100%;
    margin-left: auto;
  }

  .mapCss {
    width: 100%;
    height: 500px;
    margin-left: auto;
  }

  .numbers,
  .responsesByCity,
  .responsesByAudience,
  .responsesByAudienceDestinations,
  .stackedbar {
    width: 100%;
    margin-left: auto;
    min-height: 450px;
  }
  .stackedbar {
    width: 100%;
  }

  .titleChart {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63);
    margin-left: 10px;
  }

}
</style>
