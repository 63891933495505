<template>
  <apexchart
    type="area"
    :options="chartOptions"
    :series="series"
    height="90%"
  />
</template>

<script>
export default {
  props: {
    labels: {
      type: Array,
      default: () => [],
    },

    name: {
      type: String,
      default: () => [],
    },
    seriesData: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      series: this.seriesData,
      chartOptions: {
        chart: {
          stackType: '100%',
          stacked: true,
          type: 'area',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          show: false,
        },

        labels: this.labels,
        xaxis: {
          type: 'datetime',
          labels: {
            show: false,
          },
        },
        markers: {
          size: 4,
        },
        yaxis: {
          show: true,
          labels: {
            formatter: (value) => value.toFixed(0) + '%',
          },
          min: 0,
          max: 100,
        },
        legend: {
          show: true,
          horizontalAlign: 'left',
          position: 'top',
        },
      },
    };
  },
};
</script>

<style>

</style>
