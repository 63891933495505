<template>
  <div class="modalChartContainer">
    <div
      class="show-button"
      :style="
        'top: ' +
          ( -15) +
          'px; right: ' +
          ( -7) +
          'px;'
      "
    >
      <el-button
        icon="el-icon-search"
        circle
        size="mini"
        @click="toggleModal"
      />
    </div>
    <el-dialog
      :center="true"
      :visible.sync="isModalVisible"
    >
      <map-chart
        v-if="mapType === 'longLatMap'"
        :report-data="reportData"
        :map-type="mapType"
      />
      <map-chart
        v-if="mapType === 'usMap'"
        :report-data="reportData"
        :map-type="mapType"
      />
      <line-chart
        v-if="mapType === 'visitorFrequency'"
        :labels="
          reportData.reportData.visitorFrequency.map((item) => item.frequency)
        "
        :datasets="[
          {
            label: 'Number of Times Visited',
            data: reportData.reportData.visitorFrequency.map(
              (item) => item.sum
            ),
            borderColor: 'rgba(43, 116, 184, 1)',
            pointBackgroundColor: 'rgba(43, 116, 184, 1)',
            pointBorderColor: 'rgba(43, 116, 184, 1)',
          },
        ]"
      />
      <bar-chart
        v-if="mapType === 'visitsByHour'"
        :key="componentKey"
        style="height: 80%"
        :chart-data="reportData.reportData.visitsByHour"
        :start-hour="selectedStartHour"
        :end-hour="selectedEndHour"
      />
      <responders-bar-chart
        v-if="mapType === 'age'"
        style="height: 100%"
        :chart-data="reportData.reportData.age"
        :horizontal="false"
      />
      <gender-chart
        v-if="mapType === 'gender'"
        style="height: 100%"
        :gender="reportData.reportData.gender"
      />
      <responder-horizontal-bar
        v-if="mapType === 'householdIncome'"
        style="height: 100%"
        :household-income="reportData.reportData.householdIncome"
      />
      <responder-doughnut-chart
        v-if="mapType === 'dwellingType'"
        style="height: 100%"
        :chart-data="reportData.reportData.dwellingType"
        :chart-type="'doughnut'"
      />
      <responder-doughnut-chart
        v-if="mapType === 'maritalStatus'"
        :chart-data="reportData.reportData.maritalStatus"
        style="height: 100%"
      />
      <responder-doughnut-chart
        v-if="mapType === 'presenceOfChildren'"
        :chart-data="reportData.reportData.presenceOfChildren"
        style="height: 100%"
      />
      <responder-pie-chart
        v-if="mapType === 'education'"
        :chart-data="reportData.reportData.education"
        style="height: 100%"
      />
      <day-bar-chart
        v-if="mapType === 'visitsByWeekday'"
        style="height: 80%"
        :chart-data="reportData.reportData.visitsByWeekday"
        :chart-title="'Visits by Weekday'"
      />
      <cumulative-day-of-week
        v-if="mapType === 'cumulativeVisitsByDay'"
        style="height: 80%"
        :chart-data="reportData.reportData.cumulativeVisitsByDay"
      />
      <div
        v-if="mapType === 'politicalPartyProbability_old'"
        class="charts-container dialog"
      >
        <div
          v-for="(groupData, attribute) in groupedChartData"
          :key="attribute"
          class="chart-container"
        >
          <political-probability-doughnut
            :chart-data="groupData"
            :chart-title="attribute.replace('Party', '').trim()"
            style="height: 100%"
          />
        </div>
      </div>
      <political-probability-bar-chart
        v-if="mapType === 'politicalPartyProbability'"
        style="height: 88%"
        :chart-data="reportData.politicalData.politicalPartyProbability"
        :chart-title="'Political Party'"
      />
      <prediction-bar-chart
        v-if="mapType === 'presidentialPrimaryTurnoutProbability'"
        style="height: 88%"
        :chart-data="
          reportData.politicalData.presidentialPrimaryTurnoutProbability
        "
        :chart-title="'Presidential Primary Turnout Probability'"
      />
      <prediction-bar-chart
        v-if="mapType === 'midtermPrimaryTurnoutProbability'"
        style="height: 88%"
        :chart-data="reportData.politicalData.midtermPrimaryTurnoutProbability"
        background-color="#FFCE56"
        :chart-title="'Midterm Primary Turnout Probability'"
      />
      <federal-state-donation-bar-chart
        v-if="mapType === 'donationAmountPercentile'"
        style="height: 88%"
        :chart-data="reportData.politicalData.donationAmountPercentile"
      />
      <prediction-bar-chart
        v-if="mapType === 'federalDonorProbability'"
        style="height: 80%"
        :chart-data="reportData.politicalData.federalDonorProbability"
        background-color="#6899D1"
        :chart-title="'Federal Donor Probability'"
      />
      <prediction-bar-chart
        v-if="mapType === 'stateDonorProbability'"
        style="height: 80%"
        :chart-data="reportData.politicalData.stateDonorProbability"
        :chart-title="'Federal Donor Probability'"
      />
      <donor-prediction-amount
        v-if="mapType === 'federalDonationAmountPrediction'"
        style="height: 80%"
        :chart-data="reportData.politicalData.federalDonationAmountPrediction"
        background-color="#6899D1"
        :chart-title="'Federal Donation Amount Prediction'"
      />
      <donor-prediction-amount
        v-if="mapType === 'stateDonationAmountPrediction'"
        style="height: 80%"
        :chart-data="reportData.politicalData.stateDonationAmountPrediction"
        :chart-title="'State Donation Amount Prediction'"
      />
    </el-dialog>
    <map-chart
      v-if="mapType === 'longLatMap'"
      :report-data="reportData"
      :map-type="mapType"
    />
    <map-chart
      v-if="mapType === 'usMap'"
      :report-data="reportData"
      :map-type="mapType"
    />
    <line-chart
      v-if="mapType === 'visitorFrequency'"
      style="height: 80%"
      :labels="
        reportData.reportData.visitorFrequency.map((item) => item.frequency)
      "
      :datasets="[
        {
          label: 'Number of Times Visited',
          data: reportData.reportData.visitorFrequency.map((item) => item.sum),
          borderColor: 'rgba(43, 116, 184, 1)',
          pointBackgroundColor: 'rgba(43, 116, 184, 1)',
          pointBorderColor: 'rgba(43, 116, 184, 1)',
        },
      ]"
    />
    <bar-chart
      v-if="mapType === 'visitsByHour'"
      :key="componentKey"
      style="height: 80%"
      :chart-data="reportData.reportData.visitsByHour"
      :start-hour="selectedStartHour"
      :end-hour="selectedEndHour"
    />
    <responders-bar-chart
      v-if="mapType === 'age'"
      style="height: 100%"
      :chart-data="reportData.reportData.age"
      :horizontal="false"
    />
    <gender-chart
      v-if="mapType === 'gender'"
      style="height: 100%"
      :gender="reportData.reportData.gender"
    />
    <responder-horizontal-bar
      v-if="mapType === 'householdIncome'"
      style="height: 100%"
      :household-income="reportData.reportData.householdIncome"
    />
    <responder-doughnut-chart
      v-if="mapType === 'dwellingType'"
      style="height: 100%"
      :chart-data="reportData.reportData.dwellingType"
      :chart-type="'doughnut'"
    />
    <responder-doughnut-chart
      v-if="mapType === 'maritalStatus'"
      :chart-data="reportData.reportData.maritalStatus"
      style="height: 100%"
    />
    <responder-doughnut-chart
      v-if="mapType === 'presenceOfChildren'"
      :chart-data="reportData.reportData.presenceOfChildren"
      style="height: 100%"
    />
    <responder-pie-chart
      v-if="mapType === 'education'"
      :chart-data="reportData.reportData.education"
      style="height: 100%"
    />
    <day-bar-chart
      v-if="mapType === 'visitsByWeekday'"
      style="height: 80%"
      :chart-data="reportData.reportData.visitsByWeekday"
      :chart-title="'Visits by Weekday'"
    />
    <cumulative-day-of-week
      v-if="mapType === 'cumulativeVisitsByDay'"
      style="height: 80%"
      :chart-data="reportData.reportData.cumulativeVisitsByDay"
    />
    <div
      v-if="mapType === 'politicalPartyProbability_old'"
      class="charts-container"
    >
      <div
        v-for="(groupData, attribute) in groupedChartData"
        :key="attribute"
        class="chart-container"
      >
        <political-probability-doughnut
          :chart-data="groupData"
          :chart-title="attribute.replace('Party', '').trim()"
          style="height: 100%"
        />
      </div>
    </div>
    <political-probability-bar-chart
      v-if="mapType === 'politicalPartyProbability'"
      style="height: 88%"
      :chart-data="reportData.politicalData.politicalPartyProbability"
      :chart-title="'Political Party'"
    />
    <prediction-bar-chart
      v-if="mapType === 'presidentialPrimaryTurnoutProbability'"
      style="height: 88%"
      :chart-data="
        reportData.politicalData.presidentialPrimaryTurnoutProbability
      "
      :chart-title="'Presidential Primary Turnout Probability'"
    />
    <prediction-bar-chart
      v-if="mapType === 'midtermPrimaryTurnoutProbability'"
      style="height: 88%"
      :chart-data="reportData.politicalData.midtermPrimaryTurnoutProbability"
      background-color="#FFCE56"
      :chart-title="'Midterm Primary Turnout Probability'"
    />
    <federal-state-donation-bar-chart
      v-if="mapType === 'donationAmountPercentile'"
      style="height: 88%"
      :chart-data="reportData.politicalData.donationAmountPercentile"
    />
    <prediction-bar-chart
      v-if="mapType === 'federalDonorProbability'"
      style="height: 80%"
      :chart-data="reportData.politicalData.federalDonorProbability"
      background-color="#6899D1"
      :chart-title="'Federal Donor Probability'"
    />
    <prediction-bar-chart
      v-if="mapType === 'stateDonorProbability'"
      style="height: 80%"
      :chart-data="reportData.politicalData.stateDonorProbability"
      :chart-title="'Federal Donor Probability'"
    />
    <donor-prediction-amount
      v-if="mapType === 'federalDonationAmountPrediction'"
      style="height: 80%"
      :chart-data="reportData.politicalData.federalDonationAmountPrediction"
      background-color="#6899D1"
      :chart-title="'Federal Donation Amount Prediction'"
    />
    <donor-prediction-amount
      v-if="mapType === 'stateDonationAmountPrediction'"
      style="height: 80%"
      :chart-data="reportData.politicalData.stateDonationAmountPrediction"
      :chart-title="'State Donation Amount Prediction'"
    />
  </div>
</template>

<script>
import MapChart from '@/components/audience/reports/graphs/MapChart.vue';
import LineChart from '@/components/audience/reports/graphs/VisitorFrequency.js';
import BarChart from '@/components/audience/reports/graphs/VisitsByHour.js';
import RespondersBarChart from '@/components/attribution/reports/report-components/RespondersBarChart';
import GenderChart from '@/components/attribution/reports/report-components/GenderChart.vue';
import ResponderHorizontalBar from '@/components/attribution/reports/report-components/ResponderHorizontalBar';
import ResponderDoughnutChart from '@/components/attribution/reports/report-components/ResponderDoughnutChart';
import ResponderPieChart from '@/components/attribution/reports/report-components/ResponderPieChart';
import DayBarChart from '../graphs/VisitsByDayOfWeek.js';
import CumulativeDayOfWeek from '../graphs/CumulativeDayOfWeek.js';
import PoliticalProbabilityDoughnut from '@/components/audience/reports/graphs/PoliticalProbabilityDoughnut.js';
import PredictionBarChart from '@/components/audience/reports/graphs/PredictionBarChart.js';
import FederalStateDonationBarChart from '@/components/audience/reports/graphs/FederalStateDonationBarChart.js';
import DonorPredictionAmount from '@/components/audience/reports/graphs/DonorPredictionAmount';
import PoliticalProbabilityBarChart from '../graphs/PoliticalProbabilityBarChart';

export default {
  components: {
    MapChart,
    LineChart,
    BarChart,
    RespondersBarChart,
    GenderChart,
    ResponderHorizontalBar,
    ResponderDoughnutChart,
    ResponderPieChart,
    DayBarChart,
    CumulativeDayOfWeek,
    PoliticalProbabilityDoughnut,
    PredictionBarChart,
    FederalStateDonationBarChart,
    DonorPredictionAmount,
    PoliticalProbabilityBarChart,
  },
  props: {
    mapType: {
      type: String,
      required: true,
    },
    reportData: {
      type: Object,
      required: true,
    },
    componentKey: {
      type: String,
      required: false,
    },
    selectedStartHour: {
      type: String,
      required: false,
    },
    selectedEndHour: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  computed: {
    groupedChartData() {
      const groupedData =
        this.reportData.politicalData.politicalPartyProbability.reduce(
          (acc, item) => {
            // Initialize the group if it doesn't exist
            if (!acc[item.attribute]) {
              acc[item.attribute] = [];
            }
            // Push the current item to the group array
            acc[item.attribute].push(item);
            return acc;
          },
          {},
        );

      // Filter the data to show only the top 3 options for each attribute
      Object.keys(groupedData).forEach((attribute) => {
        groupedData[attribute] = groupedData[attribute]
          .sort((a, b) => b.value - a.value) // Sort by value in descending order
          .slice(0, 3); // Take the top 3 items
      });

      return groupedData;
    },
  },
  methods: {
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
  },
};
</script>

<style scoped>
.modalChartContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.modalChartContainer:hover .show-button {
  opacity: 1;
  visibility: visible;
}

.show-button {
  position: absolute;
  right: -7px;
  top: -15px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.charts-container {
  display: flex;
  justify-content: center; /* Center-align charts */
  flex-wrap: wrap; /* Allow charts to wrap if needed */
  width: 100%; /* Full width */
  height: 80%;
}

.charts-container.dialog {
  gap: 20px;
}

.charts-container.dialog .chart-container {
  width: 100px;
  height: 20%;
}

.chart-container {
  flex: 0 0 auto; /* Do not grow or shrink */
  width: calc(
    100% / 4 - 20px
  ); /* Adjust this to change the number of charts per row */
  height: 60%;
}

@media (max-width: 768px) {
  .chart-container {
    width: calc(100% / 2); /* Adjust to display 2 charts per row */
  }

  .footer-text {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .chart-container {
    width: 100%; /* Each chart takes full width on smaller screens */
  }
}
</style>
