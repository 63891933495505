import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['labels', 'datasets'],
  mounted() {
    const chartLabels = this.labels.slice(0, 10) || [];
    const chartDatasets = this.datasets.map((dataset, index) => ({
      ...dataset,
      data: dataset.data.slice(0, 10),
      backgroundColor: `rgba(${getColor(index)}, 0.4)`,
      borderColor: `rgba(${getColor(index)}, 1)`,
      borderWidth: 1,
      pointBackgroundColor: `rgba(${getColor(index)}, 1)`,
      pointBorderColor: `rgba(${getColor(index)}, 1)`,
      pointHoverBackgroundColor: `rgba(${getColor(index)}, 0.8)`,
      pointHoverBorderColor: `rgba(${getColor(index)}, 1)`,
      fill: true,
    }));

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 1000,
        easing: 'easeInOutQuad',
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
          },
          ticks: {
            fontColor: 'rgba(0, 0, 0, 0.7)',
          },
        }],
        yAxes: [{
          gridLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
          },
          ticks: {
            fontColor: 'rgba(0, 0, 0, 0.7)',
            autoSkip: true,
            maxTicksLimit: 5,
            callback: function(value, index, values) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
          stacked: true,
        }],
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 16,
          usePointStyle: true,
          boxWidth: 5,
          padding: 20,
        },
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      elements: {
        point: {
          radius: 0,
        },
      },
    };

    this.renderChart({ labels: chartLabels, datasets: chartDatasets }, options);
  },
};

// Helper function to generate colors based on dataset index
function getColor(index) {
  const colors = [
    '75, 192, 192',
    '54, 162, 235',
    '153, 102, 255',
    '255, 99, 132',
    '255, 159, 64',
    '255, 205, 86',
    '75, 192, 192',
    '54, 162, 235',
    '153, 102, 255',
    '255, 99, 132',
  ];
  return colors[index % colors.length];
}
