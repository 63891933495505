// BarChart.vue
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Array,
      required: true,
      default: () => [],
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      default: 'Political Party',
    },
    backgroundColor: {
      type: String,
      default: '#E99A3E',
    },
  },
  mounted() {
    const barColors = [
      {
        party: 'Liberal',
        backgroundColor: '#6899D2',
      },
      {
        party: 'Republican',
        backgroundColor: '#6899D2',
      },
      {
        party: 'Democrat',
        backgroundColor: '#6899D2',
      },
      {
        party: 'Independent',
        backgroundColor: '#6899D2',
      },
      {
        party: 'Conservative',
        backgroundColor: '#6899D2',
      },
      {
        party: 'Green',
        backgroundColor: '#6899D2',
      },
      {
        party: 'Libertarian',
        backgroundColor: '#6899D2',
      },
    ];
    const labels = this.chartData.map((item) => item.party);
    const data = this.chartData.map((item) => {
      return Number(item.total_headcount);
    });
    const dataColors = this.chartData.map((item) => barColors.find((color) => color.party === item.party).backgroundColor);
    this.renderChart(
      {
        labels,
        datasets: [
          {
            label: this.chartTitle,
            data,
            backgroundColor: dataColors,
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
        type: this.horizontal ? 'horizontalBar' : 'bar',
      },
    );
  },
};
