<template>
  <el-container>
    <el-aside
      v-if="orientation == 'landscape'"
      width="150px"
    >
      <nav class="navbar">
        <img
          v-for="(item, index) in navItems"
          v-if="item.display"
          :key="index"
          style="margin-left:10%;margin-bottom: 5%;"
          :src="item.image"
          class="navbar-image"
          :class="{ 'active': item.name === currentPage, 'grown': item.isGrown }"
          @click="navigate(item.name)"
          @mouseover="growImage(index)"
          @mouseout="shrinkImage(index)"
        >
      </nav>
    </el-aside>
    <el-main style="height: 100vh;">
      <div
        v-if="loading"
        class="loader-container"
      >
        <div class="loader" />
      </div>
      <!-- Show error message if an error occurred -->
      <div v-if="error">
        An error occurred: {{ error.message }}
      </div>
      <!-- Render reports when data is available -->
      <div
        v-if="reportData"
        class="report-container"
      >
        <div
          class="report"
        >
          <title-page-landscape
            v-if="isTitlePage"
            :report-data="reportData"
          />
          <end-page-landscape
            v-if="isEndPage"
            :report-data="reportData"
          />
          <audience-insights-one-landscape
            v-if="isAudiencePageOne"
            :report-data="reportData"
          />
          <audience-insights-two-landscape
            v-if="isAudiencePageTwo"
            :report-data="reportData"
          />
          <demographic-insights-landscape-page-one
            v-if="isDemographicPageOne"
            :report-data="reportData"
          />
          <demographic-insights-landscape-page-two
            v-if="isDemographicPageTwo"
            :report-data="reportData"
          />
          <political-insights-landscape-page-one
            v-if="isPoliticalPageOne"
            :report-data="reportData"
          />
          <political-insights-landscape-page-two
            v-if="isPoliticalPageTwo"
            :report-data="reportData"
          />
          <div
            v-if="isHeatmapPagePortrait"
            ref="heatmap"
            class="heatmap"
            style=""
          >
            <div class="header">
              <h1>Audience Heatmap</h1>
              <p v-if=" reportData.reportconfigs.heatmapDescription ">
                {{ reportData.reportconfigs.heatmapDescription }}
              </p>
            </div>
            <div class="outer-container">
              <el-container class="main-container">
                <heatmap-portrait-2
                  :report-data="reportData"
                  :heatmap-url="heatmapUrl"
                />
                <el-footer class="footer">
                  <div class="footer-content">
                    <img
                      :src="logoURL"
                      alt="Logo"
                      class="logo"
                      @error="setDefaultImage"
                    >
                  </div>
                </el-footer>
              </el-container>
            </div>
          </div>

          <div
            v-if="isHeatmapPage"
            ref="heatmap"
            class="heatmap"
            style=""
          >
            <div class="header">
              <h1>Audience Heatmap</h1>
              <p v-if=" reportData.reportconfigs.heatmapDescription ">
                {{ reportData.reportconfigs.heatmapDescription }}
              </p>
            </div>
            <div class="outer-container">
              <el-container class="main-container">
                <iframe
                  width="100%"
                  height="100%"
                  :src="heatmapUrl"
                />
                <el-footer class="footer">
                  <div class="footer-content">
                    <img
                      :src="logoURL"
                      alt="Logo"
                      class="logo"
                      @error="setDefaultImage"
                    >
                  </div>
                </el-footer>
              </el-container>
            </div>
          </div>
          <title-page-portrait
            v-if="isTitlePagePortrait"
            :report-data="reportData"
          />
          <end-page-portrait
            v-if="isEndPagePortrait"
            :report-data="reportData"
          />
          <audience-insights-one-portrait
            v-if="isAudiencePageOnePortrait"
            :report-data="reportData"
          />
          <audience-insights-two-portrait
            v-if="isAudiencePageTwoPortrait"
            :report-data="reportData"
          />
          <demographic-insights-portrait-page-one
            v-if="isDemographicPageOnePortrait"
            :report-data="reportData"
          />
          <demographic-insights-portrait-page-two
            v-if="isDemographicPageTwoPortrait"
            :report-data="reportData"
          />
          <political-insights-portrait-page-one
            v-if="isPoliticalPageOnePortrait"
            :report-data="reportData"
          />
          <political-insights-portrait-page-two
            v-if="isPoliticalPageTwoPortrait"
            :report-data="reportData"
          />
        </div>
      </div>
      <!-- Optional: Handle case when loading is done but reportData is null -->
      <div v-if="!loading && !reportData && !heatmapUrl">
        No report data found.
      </div>
    </el-main>
  </el-container>
</template>

<script>
import {
  reporting as reportingApi,
  audience as audienceApi,
} from '@/adonis-api';
import TitlePagePortrait from '@/components/audience/reports/portrait-v2/TitlePagePortrait.vue';
import TitlePageLandscape from '@/components/audience/reports/landscape/TitlePageLandscape.vue';
import EndPageLandscape from '@/components/audience/reports/landscape/EndPageLandscape.vue';
import EndPagePortrait from '@/components/audience/reports/portrait-v2/EndPagePortrait.vue';
import AudienceInsightsOneLandscape from '@/components/audience/reports/landscape/AudienceInsightsLandscapePage_1.vue';
import AudienceInsightsTwoLandscape from '@/components/audience/reports/landscape/AudienceInsightsLandscapePage_2.vue';
import DemographicInsightsLandscapePageOne from '@/components/audience/reports/landscape/DemographicInsightsLandscapePage_1.vue';
import DemographicInsightsLandscapePageTwo from '@/components/audience/reports/landscape/DemographicInsightsLandscapePage_2.vue';
import PoliticalInsightsLandscapePageOne from '@/components/audience/reports/landscape/PoliticalInsightsLandscapePage_1.vue';
import PoliticalInsightsLandscapePageTwo from '@/components/audience/reports/landscape/PoliticalInsightsLandscapePage_2.vue';
import AudienceInsightsOnePortrait from '@/components/audience/reports/portrait-v2/AudienceInsightsPortraitPage_1.vue';
import AudienceInsightsTwoPortrait from '@/components/audience/reports/portrait-v2/AudienceInsightsPortraitPage_2.vue';
import DemographicInsightsPortraitPageOne from '@/components/audience/reports/portrait-v2/DemographicInsightsPortraitPage_1V2.vue';
import DemographicInsightsPortraitPageTwo from '@/components/audience/reports/portrait-v2/DemographicInsightsPortraitPage_2.vue';
import PoliticalInsightsPortraitPageOne from '@/components/audience/reports/portrait-v2/PoliticalInsightsPortraitPage_1.vue';
import PoliticalInsightsPortraitPageTwo from '@/components/audience/reports/portrait-v2/PoliticalInsightsPortraitPage_2.vue';
// import HeatmapPortrait from '@/components/audience/reports/customComponents/HeatmapPortrait.vue';
import HeatmapPortrait2 from '@/components/audience/reports/customComponents/HeatmapPortrait2.vue';

export default {
  name: 'AttributionReport',
  components: {
    TitlePagePortrait,
    TitlePageLandscape,
    EndPageLandscape,
    EndPagePortrait,
    AudienceInsightsOneLandscape,
    AudienceInsightsTwoLandscape,
    DemographicInsightsLandscapePageOne,
    DemographicInsightsLandscapePageTwo,
    PoliticalInsightsLandscapePageOne,
    PoliticalInsightsLandscapePageTwo,
    AudienceInsightsOnePortrait,
    AudienceInsightsTwoPortrait,
    DemographicInsightsPortraitPageOne,
    DemographicInsightsPortraitPageTwo,
    PoliticalInsightsPortraitPageOne,
    PoliticalInsightsPortraitPageTwo,
    // HeatmapPortrait,
    HeatmapPortrait2,
  },
  data() {
    return {
      reportData: null,
      loading: false,
      error: null,
      heatmapUrl: null,
      currentPage: '',
    };
  },
  computed: {
    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
    reportSelections() {
      const reportSelectionsParam = this.$route.query.reportSelections;
      const reportSelectionsArray = JSON.parse(
        decodeURIComponent(reportSelectionsParam),
      );
      return reportSelectionsArray;
    },
    reportType() {
      return this.$route.path.split('/')[3];
    },
    reportId() {
      return this.$route.query.reportId;
    },
    orientation() {
      return this.$route.query.orientation || 'portrait';
    },
    isTitlePage() {
      return this.reportType === 'title_page' && this.orientation === 'landscape';
    },
    isEndPage() {
      return this.reportType === 'end_page' && this.orientation === 'landscape';
    },
    isAudiencePageOne() {
      return this.reportType === 'audience_1' && this.orientation === 'landscape';
    },
    isAudiencePageTwo() {
      return this.reportType === 'audience_2' && this.orientation === 'landscape';
    },
    isDemographicPageOne() {
      return this.reportType === 'demographic_1' && this.orientation === 'landscape';
    },
    isDemographicPageTwo() {
      return this.reportType === 'demographic_2' && this.orientation === 'landscape';
    },
    isPoliticalPageOne() {
      return this.reportType === 'political_1' && this.orientation === 'landscape';
    },
    isPoliticalPageTwo() {
      return this.reportType === 'political_2' && this.orientation === 'landscape';
    },
    isHeatmapPage() {
      return this.reportType === 'heatmap' && this.orientation === 'landscape';
    },
    isTitlePagePortrait() {
      return this.reportType === 'title_page' && this.orientation === 'portrait';
    },
    isEndPagePortrait() {
      return this.reportType === 'end_page' && this.orientation === 'portrait';
    },
    isAudiencePageOnePortrait() {
      return this.reportType === 'audience_1' && this.orientation === 'portrait';
    },
    isAudiencePageTwoPortrait() {
      return this.reportType === 'audience_2' && this.orientation === 'portrait';
    },
    isDemographicPageOnePortrait() {
      return this.reportType === 'demographic_1' && this.orientation === 'portrait';
    },
    isDemographicPageTwoPortrait() {
      return this.reportType === 'demographic_2' && this.orientation === 'portrait';
    },
    isPoliticalPageOnePortrait() {
      return this.reportType === 'political_1' && this.orientation === 'portrait';
    },
    isPoliticalPageTwoPortrait() {
      return this.reportType === 'political_2' && this.orientation === 'portrait';
    },
    isHeatmapPagePortrait() {
      return this.reportType === 'heatmap' && this.orientation === 'portrait';
    },
    navItems() {
      return [
        {
          name: 'title_page',
          image: '/images/title.webp',
          isGrown: false,
          display: true,
        },
        {
          name: 'audience_1',
          image: '/images/audience_insights_1.png',
          isGrown: false,
          display: this.reportSelections.includes(0),
        },
        {
          name: 'audience_2',
          image: '/images/audience_insights_2.png',
          isGrown: false,
          display: this.reportSelections.includes(0),
        },
        {
          name: 'demographic_1',
          image: '/images/demographic_1.png',
          isGrown: false,
          display: this.reportSelections.includes(1),
        },
        {
          name: 'demographic_2',
          image: '/images/demographic_2.png',
          isGrown: false,
          display: this.reportSelections.includes(1),
        },
        {
          name: 'political_1',
          image: '/images/political_1.png',
          isGrown: false,
          display: this.reportSelections.includes(3),
        },
        {
          name: 'political_2',
          image: '/images/political_2.png',
          isGrown: false,
          display: this.reportSelections.includes(3),
        },
        {
          name: 'heatmap',
          image: '/images/heatmap.png',
          isGrown: false,
          display: this.reportSelections.includes(2),
        },
        {
          name: 'end_page',
          image: '/images/end.webp',
          isGrown: false,
          display: true,
        },
      ];
    },
  },
  created() {
    this.loading = true;
    Promise.allSettled([this.fetchReportData(), this.fetchHeatmap()])
      .then(() => {
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        this.error = err;
      });
    this.currentPage = this.currentPage || this.reportType;
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
    scrollLeft() {
      const currentIndex = this.navItems.findIndex(
        item => item.name === this.currentPage,
      );
      if (currentIndex > 0) {
        this.navigate(this.navItems[currentIndex - 1].name);
      }
    },

    scrollRight() {
      const currentIndex = this.navItems.findIndex(
        item => item.name === this.currentPage,
      );
      if (currentIndex < this.navItems.length - 1) {
        this.navigate(this.navItems[currentIndex + 1].name);
      }
    },

    growImage(index) {
      this.navItems[index].isGrown = true;
    },
    shrinkImage(index) {
      this.navItems[index].isGrown = false;
    },
    navigate(pageName) {
      this.currentPage = pageName;

      this.$router.push({
        path: `/audience/reports/${pageName}`,
        query: {
          reportId: this.reportId,
          orientation: this.orientation,
          reportSelections: this.$route.query.reportSelections,
        },
      });
    },
    async fetchReportData() {
      const response = await reportingApi.getReportData(this.reportId);
      this.reportData = response;
    },
    async fetchHeatmap() {
      const response = await audienceApi.getHeatmapUrl(this.reportId);
      this.heatmapUrl = response.url_iframe;
    },
  },
};
</script>

  <style lang="scss" scoped>
.report-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.report {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
}
.header {
  background-color: white;
  padding: 10px;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: relative;
  padding-top: 2%;
}

.footer-content {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100px;
  max-height: 50px;
  height: auto;
  position: absolute;
  right: 45px;
}

.footer-text {
  margin: 0;
  color: gray;
  font-weight: bold;
  font-size: 20px;
}
.navbar {
  background-color: #333;
  height: 100%;
  position: fixed;
  top: 0;
  width: 135px;
  overflow-y: auto;
  padding-top: 1rem;
}

.arrow {
  cursor: pointer;
  font-size: 36px;
  margin: auto;
  color: white;
}

/* width */
.navbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.navbar::-webkit-scrollbar-track {
  background: #333;
}

/* Handle */
.navbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.navbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.right-arrow {
  margin: auto;
}
.navbar-image {
  margin-bottom: 10px;
}
.navbar-image.active,
.navbar-image.grown {
  border: 2px solid rgb(230, 82, 45);
}

.grown {
  transform: scale(1.1);
}

.arrow-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.navbar-image {
  width: 100px;
  height: 150px;
  transition: all 0.3s ease;
}
.arrow-button:active .arrow {
  transform: scale(0.9);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.heatmap {
  max-width: 100%;
  height: 100%;

  * {
    margin: 0;
  }

  .header {
    height: 10%;
    padding: 0;
  }

  .outer-container {
    background-color: lightgrey;
    width: 100%;
    max-width: 100%;
    height: 90%; /* Adjust the height based on the footer height */
    display: flex;
    flex-direction: column;
    border-radius: 8px;
  }
  .main-container {
    padding: 20px;
    border-radius: 8px;
  }
}

.main-container {
  background-color: lightgrey;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.second-row {
  flex-grow: 1;
}

iframe {
  max-width: 99.8%;
  height: 100%;
}

</style>
