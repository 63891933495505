<template>
  <div class="wrapper">
    <div class="outer-container">
      <div class="header">
        <h1>Audience Insights</h1>
        <p v-if="reportData.reportconfigs.insightDescription">
          <span>
            <h4>{{ reportData.reportconfigs.insightDescription }}</h4>
          </span>
        </p>
      </div>
      <el-container class="main-container">
        <div class="data-container">
          <div class="box">
            <div class="data">
              {{ formatNumber(reportData.reportData.uniqueDevices[0].sum) }}
            </div>
            <div class="title">
              Unique Devices
            </div>
            <hr>
            <div class="description">
              Represents the total number of unique Mobile Ad IDs
              (MAIDS/Devices) observed within the Geoframe(s) during the
              specified lookback period of the audience.
            </div>
          </div>
          <div class="box">
            <div class="data">
              {{ formatNumber(reportData.reportData.totalVisits[0].sum) }}
            </div>
            <div class="title">
              Total Visits
            </div>
            <hr>
            <div class="description">
              Represents the total number of devices observed (including initial
              and repeat visits) within the Geoframe(s) during the specified
              lookback period of the audience.
            </div>
          </div>
          <div class="box">
            <div class="data">
              {{ formatNumber(reportData.reportData.locations[0].count) }}
            </div>
            <div class="title">
              Location Count
            </div>
            <hr>
            <div class="description">
              The total number of discrete Geoframes used to capture the unique
              and total visits observed.
            </div>
          </div>
        </div>
        <div class="chart-container">
          <div class="box">
            <chart-with-modal
              :map-type="'usMap'"
              :report-data="reportData"
            />
          </div>
          <div class="box">
            <chart-with-modal
              :map-type="'longLatMap'"
              :report-data="reportData"
            />
          </div>
        </div>
        <el-footer class="footer">
          <div class="footer-content">
            <img
              :src="logoURL"
              alt="Logo"
              class="logo"
              @error="setDefaultImage"
            >
          </div>
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import ChartWithModal from '@/components/audience/reports/customComponents/ChartWithModal.vue';

export default {
  components: {
    ChartWithModal,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logoURL() {
      return this.reportData?.logoURL ?? '/images/onspot-logo.svg';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
    formatNumber(number) {
      return Number(number).toLocaleString('en-US');
    },
    addUsStates(
      svg,
      width,
      height,
      reportData,
      projection,
      colorScale,
      mapType,
    ) {
      const path = d3.geoPath().projection(projection);

      // Initialize tooltip
      const tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

      svg
        .append('g')
        .attr('class', 'states')
        .attr('transform', 'translate(50,0)')
        .selectAll('path')
        .data(reportData.states.features)
        .enter()
        .append('path')
        .attr('d', path)
        .style('fill', function (d) {
          if (mapType === 'usMap') {
            const found = reportData.visitsByStateOfResidence.find((state) => state.state === d.properties.STATE_ABBR);
            return found
              ? colorScale(Math.pow(found.sum, 0.5))
              : 'rgba(230, 230, 255, 1)';
          }
          return 'rgb(231, 237, 222)';
        })
        .attr('stroke', function () {
          return mapType === 'usMap' ? 'white' : 'black';
        })
        .attr('stroke-width', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  max-height: 100%;
}
.outer-container {
  height: 100%;
  max-height: 100%;
  width: 100%;
}

.data-container,
.chart-container {
  display: flex;
  gap: 8px;

  .box {
    background-color: white;
    padding: 12px;
    height: calc(100% - 24px);
    border-radius: 8px;
  }
}

.data-container {
  height: 25%;
}

.chart-container {
  height: 65%;
  flex-direction: column;

  .box {
    height: 45%;
  }
}

.header {
  background-color: white;
  padding: 0;
  margin-bottom: 0; /* Gap between header and main container */

  height: 10%;
}

.header h1 {
  margin: 0;
}

.header-title,
.header-description {
  margin-left: 40px; /* Margin away from the left of the screen */
}

.main-container {
  background-color: lightgrey;
  padding: 12px;
  height: 90%;
  justify-content: space-between;
  border-radius: 8px;
}
.el-row {
  background-color: lightgrey;
  margin-left: 0px !important;
  height: 44%;
}

.map-box .column5-box {
  height: 90%; /* Or a calculated vh/vw, like calc(50vh - 50px) */
}
.second-row {
  /* margin-top: 20px; */
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5% !important;
  position: relative;
}

.footer-content {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100px;
  max-height: 50px;
  height: auto;
  position: absolute;
  right: 45px;
}

.footer-text {
  margin: 0;
  color: gray;
  font-weight: bold;
  font-size: 20px;
}
.box .data {
  font-size: 20px;
  font-weight: bold;
}
.box .title {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .description {
  font-size: 14px;
}

.box hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Media query for tablets */
@media (max-width: 768px) {
  .el-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Media query for phones */
@media (max-width: 800px) {
  .el-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-height: 800px) {
}
</style>
