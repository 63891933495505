<template>
  <div class="container">
    <div class="statistics-container">
      <div class="statistic-box">
        <div
          class="number"
          :style="{ color: seniorsColor }"
        >
          {{ formattedSeniorsPresent }}
        </div>
        <div
          class="label"
          :style="{ color: labelColor }"
        >
          Seniors Present in Household
        </div>
      </div>
      <div class="divider" />
      <div class="statistic-box">
        <div
          class="number"
          :style="{ color: veteransColor }"
        >
          {{ formattedVeteransPresent }}
        </div>
        <div
          class="label"
          :style="{ color: labelColor }"
        >
          Veterans Present in Household
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeniorsAndVeterans',
  props: {
    seniorsPresent: {
      type: Number,
      required: true,
    },
    veteransPresent: {
      type: Number,
      required: true,
    },
    seniorsColor: {
      type: String,
      default: '#5b5b5b',
    },
    veteransColor: {
      type: String,
      default: '#5b5b5b',
    },
    labelColor: {
      type: String,
      default: '#5b5b5b',
    },
  },
  computed: {
    formattedSeniorsPresent() {
      return Number(this.seniorsPresent).toLocaleString();
    },
    formattedVeteransPresent() {
      return Number(this.veteransPresent).toLocaleString();
    },
  },
};
</script>

  <style scoped>
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .statistics-container {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .statistic-box {
    text-align: center;
    margin: 0 10px;
  }

  .number {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .label {
    font-size: 1.5em;
  }

  .divider {
    width: 1px;
    background-color: #ccc;
    height: 175px;
    margin: 0 20px;
  }

  @media (max-width: 768px) {
    .statistics-container {
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .divider {
      display: none;
    }
  }
  @media (max-height: 900px) {
  .statistics-container {
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }
  .seniorAndVeterans .divider {
    height: 100%;
  }
  .seniorAndVeterans .number {
    margin-bottom: 8px;
    font-size: 18px;
  }
  .seniorAndVeterans .label {
    font-size: 16px;
  }
}
  </style>
