import { render, staticRenderFns } from "./DemographicInsightsPortraitPage_1V2.vue?vue&type=template&id=a00142e8&scoped=true&"
import script from "./DemographicInsightsPortraitPage_1V2.vue?vue&type=script&lang=js&"
export * from "./DemographicInsightsPortraitPage_1V2.vue?vue&type=script&lang=js&"
import style0 from "./DemographicInsightsPortraitPage_1V2.vue?vue&type=style&index=0&id=a00142e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a00142e8",
  null
  
)

export default component.exports