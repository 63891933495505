<template>
  <div>
    <div
      v-if="loading"
      class="loader"
      style="margin: auto; margin-top: 25%;"
    />
    <div
      v-if="!loading"
      style="background: lightgrey; height: 100%;"
    >
      <div style="width:1500px;margin: auto;">
        <div
          ref="cover"
          style="padding: 10px;background:white; "
        >
          <div style="height: 70px; background-color: #EC7C30; display: flex;align-items: center; margin-top: 10px;white-space:nowrap;overflow:hidden;">
            <h1 style="margin:auto;">
              Targeted Digital Campaign Proposal
            </h1>
          </div>
          <div style="margin-top: 15%;">
            <el-row>
              <el-col
                :offset="2"
                :span="10"
              >
                <div class="logo">
                  <img
                    v-if="logo_url"
                    ref="logo"
                    style="width: 500px"
                    :src="logo_url"
                  >
                </div>
              </el-col>
              <el-col :span="2">
                <div style="height: 175px; width: 25px; background-color: black;" />
              </el-col>
              <el-col :span="10">
                <span>
                  <h1 style="margin-top: -1%; font-size: 54px;">{{ name }}</h1>
                </span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 15%;">
              <el-col
                :offset="2"
                :span="16"
              >
                <h2> Contact: {{ contact_name }}, {{ contact_phone }}, {{ contact_email }}</h2>
              </el-col>
              <el-col :span="6">
                <h2>Created: {{ formatDate(created_at) }}</h2>
              </el-col>
            </el-row>
          </div>
        </div><br>
        <div
          ref="audienceInsightOne"
          style="padding: 10px;background:white; "
        >
          <div style="height: 70px; background-color: #EC7C30; display: flex;align-items: center; margin-top: 10px;white-space:nowrap;overflow:hidden;">
            <h1 style="margin:auto;">
              Audience Insights
            </h1>
          </div>
          <el-row>
            <el-col
              v-if="heatmap"
              :span="15"
              :push="3"
            >
              <div ref="heatmap">
                <heatmap
                  v-if="heatmap"
                  class="section-small-title"
                  :features="heatmap"
                />
              </div>
            </el-col>
            <el-col
              v-if="heatmap"
              :span="4"
              :push="1"
            >
              <span
                style="margin-top:100%; margin-left: 0%;"
                class="section-small-title"
              >DWELLING TYPES</span><br>
              <div
                v-for="dwelling in dwelling_type"
                :key="dwelling.group"
              >
                <span class="section-inner-content"><i
                  v-if="dwelling.group == 'Single Family Household'"
                  style="margin-right: 5px;"
                  class="fa fa-home"
                /> <i
                  v-if="dwelling.group == 'Multi-Family Dwelling'"
                  style="margin-right: 5px;"
                  class="fa fa-city"
                /> &#160;&#160;{{ dwelling.headcount.toLocaleString() }} - {{ dwelling.group }}</span> <br>
              </div>
              <div style="text-align: center;" />
            </el-col>
          </el-row>
          <hr
            v-if="heatmap"
            style="width:1000px; margin: auto; color:black;"
          ><br><br>
          <br v-if="!heatmap">
          <br v-if="!heatmap">
          <br v-if="!heatmap">
          <el-row>
            <el-col
              :span="12"
              :push="4"
            >
              <span class="section-small-title">HOUSEHOLD INCOME</span><br>
              <horizontal-bar
                v-if="household_income"
                :data="household_income"
              />
            </el-col>
            <el-col :span="12">
              <span class="section-small-title">MARITAL STATUS</span><br>
              <pie-chart
                v-if="marital_status"
                :data="marital_status"
                :color-step="0.2"
              />
            </el-col>
          </el-row>
          <hr
            v-if="!heatmap"
            style="width:1000px; margin: auto; margin-top: 75px; color:black;"
          ><br><br>
          <el-row v-if="!heatmap">
            <el-col>
              <span
                style="margin-top: 20px;"
                class="section-small-title"
              >DWELLING TYPES</span><br>
              <div
                v-for="dwelling in dwelling_type"
                :key="dwelling.group"
              >
                <span class="section-inner-content"><i
                  v-if="dwelling.group == 'Single Family Household'"
                  style="margin-right: 5px;"
                  class="fa fa-home"
                /> <i
                  v-if="dwelling.group == 'Multi-Family Dwelling'"
                  style="margin-right: 5px;"
                  class="fa fa-city"
                /> &#160;&#160;{{ dwelling.headcount.toLocaleString() }} - {{ dwelling.group }}</span> <br>
              </div>
              <div style="text-align: center;" />
            </el-col>
          </el-row>
        </div><br>
        <div
          ref="audienceInsightTwo"
          style="padding: 10px;background:white; "
        >
          <div style="height: 70px; background-color: #EC7C30; display: flex;align-items: center; margin-top: 10px;white-space:nowrap;overflow:hidden;">
            <h1 style="margin:auto;">
              Audience Insights
            </h1>
          </div>
          <br><br>
          <el-row>
            <el-col :span="12">
              <span class="section-small-title">PRESENCE OF CHILDREN</span><br>
              <pie-chart
                v-if="children"
                :data="children"
                :color-step="0.3"
              />
            </el-col>
            <el-col :span="12">
              <span class="section-small-title">GENDER</span><br>
              <pie-chart
                v-if="gender"
                :data="gender"
                :color-step="0.3"
              />
            </el-col>
          </el-row><br>
          <hr style="width:1000px; margin: auto; color:black;"><br><br><br>
          <el-row>
            <span class="section-small-title">AGE</span>
            <el-col>
              <div style="margin-left: 22%;">
                <histogram-chart
                  v-if="age"
                  :data="age"
                />
              </div>
            </el-col>
          </el-row>
        </div><br>
        <div
          ref="audienceInsightThree"
          style="padding: 10px;background:white; "
        >
          <div style="height: 70px; background-color: #EC7C30; display: flex;align-items: center; margin-top: 10px;white-space:nowrap;overflow:hidden;">
            <h1 style="margin:auto;">
              Audience Insights
            </h1>
          </div>
          <br><br>
          <el-row>
            <el-col
              v-if="uniqueDevices"
              :span="8"
              :push="2"
            >
              <span style="font-weight: bold;">
                UNIQUE DEVICES:<br><br>
              </span>
              Devices: {{ uniqueDevices.toLocaleString() }}<br><br>
              <img
                src="/images/cell-phone.svg"
                style="width:25%;"
              ><br><br>
              Estimated Extension Devices: {{ extensionDevices.toLocaleString() }}<br>
              Estimated Total Devices: {{ (uniqueDevices + extensionDevices).toLocaleString() }}
            </el-col>
            <el-col
              v-if="visitsByState"
              :span="8"
            >
              <span style="font-weight: bold;margin-left:25%;">
                VISITS BY STATE:<br><br>
              </span>
              <chloropleth
                v-if="visitsByState"
                :states-sum="visitsByState"
              />
            </el-col>
            <el-col
              v-if="visitsZipCode"
              :span="8"
              :push="2"
            >
              <span style="font-weight: bold;">
                TOP VISITS BY ZIPCODE:<br><br>
              </span>
              <bar-chart
                v-if="visitsZipCode"
                name="ZipChart"
                :bar-length="1"
                :data="visitsZipCode"
              />
            </el-col>
          </el-row><br>
          <hr
            v-if="visitsByState"
            style="width:1000px; margin: auto; color:black;"
          ><br v-if="visitsByState"><br v-if="visitsByState"><br v-if="visitsByState">
          <el-row>
            <el-col
              :push="2"
              :span="8"
            >
              <span style="font-weight: bold;">INTERESTS</span><br><br>
              <bar-chart
                v-if="interests"
                name="Bar1"
                :bar-length="1"
                :data="interests"
              />
            </el-col>
            <el-col
              :push="2"
              :span="8"
            >
              <span style="font-weight: bold;">CHARITY CONTRIBUTORS</span><br><br>
              <bar-chart
                v-if="charity"
                name="Bar2"
                :bar-length="1"
                :data="charity"
              />
            </el-col>
            <el-col
              :push="2"
              :span="8"
            >
              <span style="font-weight: bold;">MEDIA USAGE</span><br><br>
              <bar-chart
                v-if="mediaUsage"
                name="Bar3"
                :bar-length="1"
                :data="mediaUsage"
              />
            </el-col>
          </el-row>
        </div><br>
        <div
          ref="budget"
          style="padding: 10px;background:white; "
        >
          <div style="height: 70px; background-color: #EC7C30; display: flex;align-items: center; margin-top: 10px;white-space:nowrap;overflow:hidden;">
            <h1 style="margin:auto;">
              Campaign Budget / Scale / Reach
            </h1>
          </div><br><br>
          <div style="border: 2px solid orange; border-radius: 25px; padding: 20px; margin-right: 10%; margin-left: 10%;">
            <el-row>
              <el-col
                :span="8"
                :push="3"
              >
                <span class="section-inner-budget">
                  <span style="font-weight: bold;">Campaign Length:</span> {{ campaign_length }} days<br><br>
                  <span style="font-weight: bold;">Budget:</span> ${{ budget.toLocaleString() }}<br>
                </span>
              </el-col>
              <el-col
                :span="8"
                :push="5"
              >
                <span style="font-weight: bold; font-size: 1.25vw">
                  Media Types:
                </span>
                <ul class="ul-indent">
                  <li
                    v-for="(value, media) in media_types"
                    :key="media"
                  >
                    {{ media }} - ${{ value.cpm }} CPM - {{ value.campaignPercent }}%
                  </li>
                </ul>
              </el-col>
            </el-row>
          </div><br>
          <el-row>
            <el-col
              :offset="4"
              :span="6"
              :v-if="totalImpressions"
            >
              <div style="border: 2px solid orange; border-radius: 25px; padding: 20px; margin-right: 30%; background-color: rgba(255, 165, 0, 0.1);">
                Budget #1: ${{ (budget).toLocaleString() }}<br>
                Imp/Month: {{ impressionsPerMonth(1) }}<br>
                Spend/Month: ${{ getSpendPerMonth(1) }}
              </div><br><br>
              <div style="border: 2px solid orange; border-radius: 25px; padding: 20px; margin-right: 30%; ">
                <div
                  v-for="(value, medias) in media_types"
                  :key="medias"
                >
                  {{ medias }}:<br>
                  <ul>
                    <li>
                      Budget:
                      ${{
                        (((value.campaignPercent / 100) * budget))
                          .toLocaleString()
                      }}
                    </li>
                    <li>
                      Impressions:
                      {{
                        Math.round(
                          (((value.campaignPercent / 100) * budget) / value.cpm) *
                            1000
                        ).toLocaleString()
                      }}
                    </li>
                    <li>CPM: ${{ value.cpm }}</li>
                  </ul>
                </div><br>
                Total Impressions: {{ Math.round((totalImpressions)).toLocaleString() }}
              </div>
            </el-col>
            <el-col
              :v-if="budget"
              :span="6"
            >
              <div style="border: 2px solid orange; border-radius: 25px; padding: 20px; margin-right: 30%; background-color: rgba(255, 165, 0, 0.1);">
                Budget #2: ${{ (budget * 2).toLocaleString() }}<br>
                Imp/Month: {{ impressionsPerMonth(2) }}<br>
                Spend/Month: ${{ getSpendPerMonth(2) }}
              </div><br><br>
              <div style="border: 2px solid orange; border-radius: 25px; padding: 20px; margin-right: 30%; ">
                <div
                  v-for="(value, medias) in media_types"
                  :key="medias"
                >
                  {{ medias }}:<br>
                  <ul>
                    <li>
                      Budget:
                      ${{
                        ((value.campaignPercent / 100) * budget * 2)
                          .toLocaleString()
                      }}
                    </li>
                    <li>
                      Impressions:
                      {{
                        Math.round(
                          (((value.campaignPercent / 100) * budget) / value.cpm) *
                            1000 *
                            2
                        ).toLocaleString()
                      }}
                    </li>
                    <li>CPM: ${{ value.cpm }}</li>
                  </ul>
                </div><br>
                Total Impressions: {{ Math.round((totalImpressions * 2)).toLocaleString() }}
              </div>
            </el-col>
            <el-col
              :v-if="budget"
              :span="6"
            >
              <div style="border: 2px solid orange; border-radius: 25px; padding: 20px; margin-right: 30%; background-color: rgba(255, 165, 0, 0.1);">
                Budget #3: ${{ (budget * 3).toLocaleString() }}<br>
                Imp/Month: {{ impressionsPerMonth(3) }}<br>
                Spend/Month: ${{ getSpendPerMonth(3) }}
              </div><br><br>
              <div style="border: 2px solid orange; border-radius: 25px; padding: 20px; margin-right: 30%; ">
                <div
                  v-for="(value, medias) in media_types"
                  :key="medias"
                >
                  {{ medias }}:<br>
                  <ul>
                    <li>
                      Budget:
                      ${{
                        ((value.campaignPercent / 100) * budget * 3)
                          .toLocaleString()
                      }}
                    </li>
                    <li>
                      Impressions:
                      {{
                        Math.round(
                          (((value.campaignPercent / 100) * budget) / value.cpm) *
                            1000 *
                            3
                        ).toLocaleString()
                      }}
                    </li>
                    <li>CPM: ${{ value.cpm }}</li>
                  </ul>
                </div><br>
                Total Impressions: {{ Math.round((totalImpressions * 3)).toLocaleString() }}
              </div>
            </el-col>
          </el-row>
        </div><br>
        <div
          ref="io"
          style="background-color: white;"
        >
          <el-row v-if="io">
            <span class="section-inner-title">ONSPOT MEDIA INSERTION ORDER</span><br><br>
            <el-row style="border-style: solid;">
              <el-col>
                <div style="width: 100%; background-color: #E46C0A; height: 15px;" />
              </el-col>
            </el-row>
            <el-row style="border-style: solid;">
              <el-col :span="3">
                <div class="grid-content">
                  Date:
                </div>
                <div class="grid-content">
                  Campaign:
                </div>
                <div class="grid-content">
                  Total IO Amount:
                </div>
                <div class="grid-content">
                  IO Number:
                </div>
                <div class="header-content">
                  Publisher Information
                </div>
                <div class="grid-content">
                  Name:
                </div>
                <div
                  style="height:60px"
                  class="grid-content"
                >
                  Phone:
                </div>
                <div
                  style="height:50px"
                  class="grid-content"
                >
                  E-mail:
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="header-content" />
                <div class="grid-content" />
                <div
                  style="height:60px"
                  class="grid-content"
                />
                <div
                  style="height:50px"
                  class="grid-content"
                />
              </el-col>
              <el-col :span="10">
                <div class="section-inner-content">
                  <ul style="padding: 0; list-style-type: none;">
                    <li>
                      <img
                        src="/images/onspot-logo.svg"
                        width="100%"
                        height="100"
                        style="margin-top: 20%;"
                      >
                      <p>Smarter Targeting for Mobile Marketing</p>
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col :span="3">
                <div class="header-content">
                  Contact Information
                </div>
                <div class="grid-content">
                  Contact Name:
                </div>
                <div class="grid-content">
                  Phone:
                </div>
                <div class="grid-content">
                  E-mail:
                </div>
                <div class="header-content" />
                <div class="grid-content">
                  Company Name:
                </div>
                <div
                  style="height:60px"
                  class="grid-content"
                >
                  Phone:<br>Please Send<br>Invoices to:
                </div>
                <div
                  style="height:50px"
                  class="grid-content"
                >
                  Address To<br>Send Invoice:
                </div>
              </el-col>
              <el-col :span="4">
                <div class="header-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="header-content" />
                <div class="grid-content" />
                <div
                  style="height:60px"
                  class="grid-content"
                />
                <div
                  style="height:50px"
                  class="grid-content"
                />
              </el-col>
            </el-row>
            <el-row style="border-style: solid;">
              <el-col>
                <div style="width: 100%; background-color: #E46C0A; height: 15px;" />
              </el-col>
            </el-row>
            <el-row style="border-style: solid;">
              <el-col>
                <div style="width: 100%; background-color: #FFFFFF; height: 40px;" />
              </el-col>
            </el-row>
            <el-row style="border-style: solid;">
              <el-col :span="2">
                <div class="grid-content-centered">
                  Placement
                </div>
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                />
              </el-col>
              <el-col :span="2">
                <div class="grid-content-centered">
                  Contracted Site
                </div>
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                />
              </el-col>
              <el-col :span="2">
                <div class="grid-content-centered">
                  Ad Size
                </div>
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                />
              </el-col>
              <el-col :span="4">
                <div class="grid-content-centered">
                  URL
                </div>
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                />
              </el-col>
              <el-col :span="4">
                <div class="grid-content-centered">
                  Targeting
                </div>
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                />
              </el-col>
              <el-col :span="2">
                <div class="grid-content-centered">
                  Start Date
                </div>
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                />
              </el-col>
              <el-col :span="2">
                <div class="grid-content-centered">
                  End Date
                </div>
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                />
              </el-col>
              <el-col :span="1">
                <div class="grid-content-centered">
                  CPM
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                >
                  TOTAL
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content-centered">
                  Contracted Impressions
                </div>
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div class="grid-content" />
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                />
              </el-col>
              <el-col :span="2">
                <div class="grid-content-centered">
                  Contracted Spend
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div class="grid-content">
                  $
                </div>
                <div
                  class="grid-content"
                  style="height: 20px !important;"
                >
                  $
                </div>
              </el-col>
              <el-row style="border-style: solid;">
                <el-col>
                  <div style="width: 100%; background-color: black; height: 15px;" />
                </el-col>
              </el-row>
              <el-row style="border-style: solid;">
                <el-col>
                  <div style="width: 100%; background-color: white; height: 80px;" />
                </el-col>
              </el-row>
              <el-row style="border-style: solid;">
                <el-col>
                  <div style="width: 100%; background-color: black; color: white;">
                    Terms and Conditions
                  </div>
                </el-col>
              </el-row>
              <el-row style="border-style: solid;">
                <el-col>
                  <div style="width: 100%; background-color: white;">
                    Each party warrants to the other that, during the term of this Agreement, it shall comply with all applicable laws and regulations (including but not limited to laws governing privacy, and data protection), and with the Self-Regulatory Principles Governing Online Preference Marketing promulgated by the rules set forth by the IAB/AAAA Terms & Conditions Version 3.0. (Copy available upon request). © 2010 - 11 - 4A’s/IAB Standard Terms and Conditions Version 3.The Parties each recognize that the other’s Confidential Information constitutes highly valuable information. The Parties agree that they: (i) will keep confidential the other’s Information; (ii) to agree to be bound by the confidentiality obligations of this agreement or mutual NDA. This Agreement is effective upon execution by both parties until the end of the Campaign. Purchaser agrees to pay all CPM costs on net 30 day terms; further agrees to provide monthly and end of campaign reporting either via DMP or Ad Network, at the end of each month as well as end of the campaign on the total number of impressions served against the Data provided by OnSpot. Payment is due 30 days from invoice date. Late payment will incur a 1.75% per month charge for each month payment is late past original due date.
                  </div>
                </el-col>
              </el-row>
              <el-row style="border-style: solid;">
                <el-col :span="6">
                  <div class="grid-content">
                    Onspot Authorized Signature:
                  </div>
                  <div class="grid-content">
                    Name:
                  </div>
                  <div class="grid-content">
                    Title:
                  </div>
                  <div class="grid-content">
                    Date:
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content" />
                  <div class="grid-content" />
                  <div class="grid-content" />
                  <div class="grid-content" />
                </el-col>
                <el-col :span="6">
                  <div class="grid-content">
                    [CLIENT] Authorized Signature:
                  </div>
                  <div class="grid-content">
                    Name:
                  </div>
                  <div class="grid-content">
                    Title:
                  </div>
                  <div class="grid-content">
                    Date:
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content" />
                  <div class="grid-content" />
                  <div class="grid-content" />
                  <div class="grid-content" />
                </el-col>
              </el-row>
            </el-row>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { audience as audienceApi } from '@/adonis-api';
import PieChart from '../../d3charts/PieChart.vue';
import BarChart from '../../d3charts/BarChart.vue';
import HistogramChart from '../../d3charts/HistogramChart.vue';
import Heatmap from '../../d3charts/Heatmap.vue';
import JsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import Chloropleth from '../../d3charts/Chloropleth';
import HorizontalBar from '../../d3charts/HorizontalBar.vue';

export default {
  components: {
    PieChart,
    BarChart,
    HistogramChart,
    Heatmap,
    Chloropleth,
    HorizontalBar,
  },

  data() {
    return {
      household_income: null,
      dwelling_type: null,
      marital_status: null,
      interests: null,
      budget: null,
      campaign_length: null,
      media_types: null,
      children: null,
      age: null,
      gender: null,
      charity: null,
      mediaUsage: null,
      sumOfMediaTypes: null,
      uniqueDevices: null,
      visitsByState: null,
      heatmap: null,
      creative_explaination: null,
      io: null,
      logo_url: null,
      audience_scope: null,
      extensionDevices: null,
      visitsZipCode: null,
      totalImpressions: null,
      creative_text: null,
      name: null,
      contact_name: null,
      contact_email: null,
      contact_phone: null,
      created_at: null,
      generating: false,
      doc: new JsPDF({ orientation: 'l' }),
      loading: true,
      audience_type: null,
    };
  },

  computed: {},
  async mounted() {

  },
  async created() {
    this.loading = true;
    const uuid = this.$route.params.uuid;
    const response = await audienceApi.getProposalData({ uuid_endpoint: uuid });
    await this.toDataURL(response.data.logo_url);
    this.contact_name = response.data.contact_name;
    this.contact_email = response.data.contact_email;
    this.contact_phone = response.data.contact_phone;
    this.created_at = response.data.created_at;
    this.name = response.data.name;
    this.household_income = response.data.household_income;
    this.marital_status = response.data.marital_status;
    this.dwelling_type = response.data.dwelling_type;
    this.interests = response.data.interests;
    this.age = response.data.age;
    this.children = response.data.children;
    this.gender = response.data.gender;
    this.mediaUsage = response.data.mediaUsage;
    this.charity = response.data.charity;
    this.media_types = response.data.media_types;
    this.campaign_length = response.data.campaign_length;
    this.budget = response.data.budget;
    this.audience_scope = response.data.audience_scope;
    this.audience_type = response.data.type;
    this.visitsByState = response.data.visitsByState;
    this.visitsZipCode = response.data.visitsZipCode.filter(zip => zip.sum > 0)
      .sort((a, b) => {
        return b.sum - a.sum;
      })
      .splice(0, 5).map(item => { return { attribute: item.zip_code, headcount: item.sum }; });
    this.calculateExtDevices();
    this.uniqueDevices = response.data.uniqueDevices ? response.data.uniqueDevices : response.data.audience_count;
    if (response.data.audience_type === 'GEOFRAME') {
      //this.sumMediaTypes(this.mediaUsage);
      this.heatmap = response.data.heatmap;
    }
    this.creative_explaination = response.data.creative_explaination;
    this.creative_text = response.data.creative_text;
    this.io = response.data.io;
    await this.calculateTotalImpressions();
    this.loading = false;
  },

  updated() {
    this.$nextTick(function() {
      if (typeof this.$refs.budget !== 'undefined' && typeof this.$refs.audienceInsightOne !== 'undefined' && typeof this.$refs.audienceInsightTwo !== 'undefined' && (typeof this.$refs.heatmap !== 'undefined' || this.audience_type !== 'GEOFRAME') && typeof this.$refs.logo !== 'undefined' && typeof this.$refs.cover !== 'undefined' && this.generating === false) {
        this.generating = true;
        if (this.$route.query.download === 'true') {
          this.$notify({
            title: 'Generating PDF',
            message: 'Your PDF is generating. Please wait this may take a minute..',
            type: 'success',
          });
          this.handleDownloadProposal();
        }
      }
    });
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async calculateTotalImpressions() {
      let sumOfImpressions = 0;
      const mediaTypes = this.media_types;
      const tmpBudget = this.budget;
      Object.keys(this.media_types).forEach(function(key) {
        sumOfImpressions += (((mediaTypes[key].campaignPercent / 100) * tmpBudget) / mediaTypes[key].cpm) * 1000;
      });
      this.totalImpressions = sumOfImpressions.toFixed(2);
    },

    calculateExtDevices() {
      if (!this.audience_scope || this.audience_scope.length < 1) {
        this.extensionDevices = 0;
        return;
      }

      let estimatedSum = 0;
      for (const idx in this.audience_scope) {
        if (this.audience_scope[idx] === 'Household Extension') {
          estimatedSum += this.uniqueDevices * 2.5;
        }
        if (this.audience_scope[idx] === 'Lookalike Extension') {
          estimatedSum += this.uniqueDevices * 4;
        }
        if (this.audience_scope[idx] === 'Social Extension') {
          estimatedSum += this.uniqueDevices * 2;
        }
        if (this.audience_scope[idx] === 'Legacy HH Extension') {
          estimatedSum += this.uniqueDevices * 4;
        }
      }
      this.extensionDevices = Math.round(estimatedSum);
    },
    async handleDownloadProposal() {
      const that = this;
      const filename = this.name;
      let coverimg = new Image();
      let adimg2 = new Image();
      let aiimg = new Image();
      let budgetimg = new Image();
      let adimg = new Image();
      let ioimg = new Image();
      await this.sleep(5000);
      await domtoimage
        .toPng(this.$refs.cover)
        .then(function(dataUrl) {
          coverimg = dataUrl;
        })
        .catch(function(error) {
          // console.error('Error:', error);
          that.$alert('There was an error processing your PDF. Contact admin.', error);
        });
      that.doc.addImage(coverimg, 'PNG', -1.5, 0, 300, 175);
      await domtoimage
        .toPng(this.$refs.audienceInsightOne)
        .then(function(dataUrl) {
          adimg = dataUrl;
        })
        .catch(function(error) {
          // console.error('Error:', error);
          that.$alert('There was an error processing your PDF. Contact admin.', error);
        });
      that.doc.addPage();
      that.doc.setPage(2);
      if (this.heatmap) {
        that.doc.addImage(adimg, 'PNG', -3, 0, 300, 200);
      } else {
        that.doc.addImage(adimg, 'PNG', -1.5, 0, 300, 175);
      }
      await domtoimage
        .toPng(this.$refs.audienceInsightTwo)
        .then(function(dataUrl) {
          adimg2 = dataUrl;
        })
        .catch(function(error) {
          // console.error('Error:', error);
          that.$alert('There was an error processing your PDF. Contact admin.', error);
        });
      that.doc.addPage();
      that.doc.setPage(3);
      that.doc.addImage(adimg2, 'PNG', -1.5, 0, 300, 200);
      that.doc.addPage();
      that.doc.setPage(4);
      await domtoimage
        .toPng(this.$refs.audienceInsightThree)
        .then(function(dataUrl) {
          aiimg = dataUrl;
        })
        .catch(function(error) {
          // console.error('Error:', error);
          that.$alert('There was an error processing your PDF. Contact admin.', error);
        });
      that.doc.addImage(aiimg, 'PNG', -1.5, 0, 322.5, 180);
      await domtoimage
        .toPng(this.$refs.budget)
        .then(function(dataUrl) {
          budgetimg = dataUrl;
        })
        .catch(function(error) {
          // console.error('Error:', error);
          that.$alert('There was an error processing your PDF. Contact admin.', error);
        });
      that.doc.addPage();
      that.doc.setPage(5);
      that.doc.addImage(budgetimg, 'PNG', -1.5, 0, 300, 200);
      if (this.io === true) {
        await domtoimage
          .toPng(this.$refs.io)
          .then(function(dataUrl) {
            ioimg = dataUrl;
          })
          .catch(function(error) {
            // console.error('Error:', error);
            that.$alert('There was an error processing your PDF. Contact admin.', error);
          });
        that.doc.addPage();
        that.doc.setPage(6);
        that.doc.addImage(ioimg, 'JPEG', 5, 5, 285, 195);
      }
      that.doc.save(filename);
    },
    impressionsPerMonth(multiplier) {
      return (Math.round((this.totalImpressions / this.campaign_length) * 30 * multiplier)).toLocaleString();
    },
    // sumMediaTypes() {
    //   for (const idx in this.mediaUsage) {
    //     this.sumOfMediaTypes += this.mediaUsage[idx].headcount;
    //   }
    // },
    getSpendPerMonth(multiplier) {
      return (Math.round((((this.budget / this.campaign_length) * 30 * multiplier) * 100)) / 100).toLocaleString();
    },
    formatDate(date) {
      const formatDate = new Date(date);
      return formatDate.toDateString();
    },
    async toDataURL(url) {
      const that = this;
      fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })).then(dataUrl => {
          that.logo_url = dataUrl;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep( .bg) {
  background-color: grey !important;
}
.row-style {
  background: #fcfcfc;
  border: 1px solid #ccc;
  border-bottom: 2px solid #bbb;
  border-top: 1px solid #ddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  margin: 10px !important;
  padding: 20px !important;
}

.col-style {
  border-left: 1px solid black;
}

.proposal-scheme {
  font-weight: 300;
  padding-bottom: 5em;
}

.reports-page {
  margin: 1em 1em 0 1em;
}
.section-wrapper {
  width: 100%;
  padding-bottom: 1em;
}
.section-small {
  border: 1px solid black;
  display: inline-block;
  width: 25%;
}

.center-img {
  display: block;
  margin: auto;
  padding-left: 1%;
}

.creative-text {
  font-size: 1.25vw;
  display: flex;
  margin-left: 25%;
  word-wrap: break-word;
}

.section-inner-content {
  font-size: 1vw;
  word-wrap: break-word;
  text-align: center;
  display: flex;
  justify-content: center;
}

.name {
  font-size: 1vw;
  text-align: center;
  display: flex;
  justify-content: center;
}

.section-inner-budget {
  font-size: 1.25vw;
}

.section-inner-title {
  font-size: 2vw;
  text-align: center;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  font-weight: bold;
}

.section-small-title {
  font-size: 1vw;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  font-weight: bold;
}

.section-interests-small-title {
  font-size: 1vw;
  display: flex;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
}

.section-small-img {
  display: block;
  margin: auto;
}

.section-big {
  border: 1px solid black;
  display: inline-block;
  width: 70%;
}
.header {
  display: flex;

  .company-info {
    text-align: right;
  }
}

.logo {
  margin-top: 5%;
  text-align:center;
}

.ul-indent {
  list-style-position: inside;
  padding-left: 0 !important;
  font-size: 1.25vw;
}

.grid-content {
  border: 1px black;
  border-style: solid;
  height: 36px;
  display: -webkit-flex;
  display: flex;
  align-items: center;

}

.grid-content-centered {
  justify-content: center;
  border: 1px black;
  border-style: solid;
  height: 56px;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.header-content {
  border: 1px black;
  border-style: solid;
  height: 36px;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: white;
  background-color: black;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #EC7C30;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
