
import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    chartTitle: {
      type: String,
      default: 'Political Party Probability',
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          position: 'bottom',
          text: this.chartTitle,
          fontColor: 'grey',
          fontStyle: 'bold',
          fontSize: 14,
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 30,
            fontSize: 16,
            fontStyle: 'bold',
          },
        },
      },
    };
  },
  mounted() {
    // Define a mapping of groups to specific colors
    const groupColorMap = {
      '0%-10%': '#FF6384',
      '11%-20%': '#36A2EB',
      '21%-30%': '#FFCE56',
      '31%-40%': '#4BC0C0',
      '41%-50%': '#9966FF',
      '51%-60%': '#FF9F40',
      '61%-70%': '#C0C0C0',
      '71%-80%': '#FFA07A',
      '81%-90%': '#8A2BE2',
      '91%-100%': '#DEB887',
      // Add more mappings as needed
    };

    // Map the data to the format expected by Chart.js
    const labels = this.chartData.map(item => item.group);
    const data = this.chartData.map(item => item.headcount);
    const backgroundColors = this.chartData.map(item => groupColorMap[item.group] || '#CCCCCC'); // Fallback color if group not found

    // Render the chart
    this.renderChart(
      {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundColors,
            data: data,
          },
        ],
      },
      this.options,
    );
  },
  methods: {

  },
};
