<template>
  <div>
    <svg
      id="hist"
      width="800"
      height="400"
    />
  </div>
</template>
<script>
import * as d3 from 'd3';
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      xAxisLabels: [],
    };
  },
  mounted() {
    const marge = { top: 60, bottom: 60, left: 60, right: 60 };
    const svg = d3.select('#hist');
    const width = svg.attr('width');
    const height = svg.attr('height');
    const g = svg
      .append('g')
      .attr('transform', 'translate(' + marge.top + ',' + marge.left + ')');
    const dataset = this.formatData(this.data);
    const xScale = d3
      .scaleBand()
      .domain(d3.range(dataset.length))
      .rangeRound([0, width - marge.left - marge.right]);
    const xAxis = d3.axisBottom(xScale);
    const labels = this.xAxisLabels;
    xAxis.tickFormat(function(d) {
      return labels[d];
    });
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(dataset)])
      .range([height - marge.top - marge.bottom, 0]);
    const yAxis = d3.axisLeft(yScale);
    yAxis.tickFormat(function(d) {
      return d + '%';
    });
    g.append('g')
      .attr(
        'transform',
        'translate(' + 0 + ',' + (height - marge.top - marge.bottom) + ')',
      )
      .call(xAxis);
    g.append('g')
      .attr('transform', 'translate(0, 0)')
      .call(yAxis);
    const gs = g
      .selectAll('.rect')
      .data(dataset)
      .enter()
      .append('g');
    const rectPadding = 20;
    gs.append('rect')
      .attr('x', function(d, i) {
        return xScale(i) + rectPadding / 2;
      })
      .attr('y', function(d) {
        return yScale(d);
      })
      .attr('width', function() {
        return xScale.step() - rectPadding;
      })
      .attr('height', function(d) {
        return height - marge.top - marge.bottom - yScale(d);
      })
      .attr('fill', 'lightblue');
    gs.append('text')
      .attr('x', function(d, i) {
        return xScale(i) + rectPadding / 2;
      })
      .attr('y', function(d) {
        return yScale(d);
      })
      .attr('dx', function() {
        (xScale.step() - rectPadding) / 2; // eslint-disable-line
      });
  },
  methods: {
    formatData(data) {
      const tmp = [];
      for (const set in data) {
        tmp.push(data[set].ratio * 100);
        this.xAxisLabels.push(data[set].group);
      }
      return tmp;
    },
  },
};
</script>
<style scoped></style>
