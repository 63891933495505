<template>
  <div class="container top-table">
    <h1 class="title">
      {{ title }}
    </h1>
    <el-table
      :data="topFiveData"
      :border="false"
      fit
      style="width: 100%;"
      height="100%"
    >
      <el-table-column
        prop="attribute"
        label="Category"
        align="left"
      >
        <template v-slot="scope">
          <span class="category">{{ scope.row.attribute }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="headcount"
        label="Headcount"
        align="right"
      >
        <template v-slot="scope">
          <span
            :style="{ color: getHeatmapColor(scope.row.headcount)}"
            class="category"
          >
            {{ formatNumber(scope.row.headcount) }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Lifestyle - Top Contributors to Charity',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    topFiveData() {
      return [...this.data]
        .sort((a, b) => Number(b.headcount) - Number(a.headcount))
        .slice(0, 5);
    },
  },
  methods: {
    formatNumber(value) {
      return parseInt(value).toLocaleString();
    },
    getHeatmapColor(value) {
      const maxHeadcount = Math.max(...this.data.map(item => Number(item.headcount)));
      const intensity = value / maxHeadcount;
      const hue = 30;
      const saturation = 100;
      const lightness = 100 - (intensity * 50);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },
  },
};
</script>

<style>
.top-table .cell {
  padding:  0 24px !important;
}
</style>

  <style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

  .title {
    font-size: 16px;
    margin-bottom: 20px;
    color: #333;
    text-align: left;
  }

  .category {
    font-weight: bold;
    color: #333;
  }

  /* Remove all grid lines */
  .el-table .el-table__body tr td, .el-table .el-table__header tr th {
    border-bottom: none;
  }

  .el-table .el-table__body tr td:first-child, .el-table .el-table__header tr th:first-child {
    border-left: none;
  }

  .el-table .el-table__body tr td:last-child, .el-table .el-table__header tr th:last-child {
    border-right: none;
  }
  .full-height-table {
  height: 100%;
}
  </style>
