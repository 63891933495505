<template>
  <div>
    <div class="flex-container">
      <div
        v-for="key in keys"
        :key="key"
        class="numbers card-style"
      >
        <titled-number-chart
          :title="data[key].label"
          :sum="Number(data[key].sum).toLocaleString('en-US')"
          :tooltip="data[key].tooltip"
          :tooltip-pos-top="50"
          :breakpoint="breakpoint"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TitledNumberChart from '../charts/TitledNumber.vue';
import StackedBar from '../charts/StackedBar.vue';
import TableChart from '../charts/TableChart.vue';
import MapChart from '../charts/MapChart.vue';
export default {
  name: 'AttributionFootfallDashboard',
  components: {
    TitledNumberChart,
    StackedBar,
    MapChart,
    TableChart,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      keys: Object.keys(this.data).slice(1, 7),
    };
  },
  computed: {
    breakpoint() {
      return this.windowWidth >= 768;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },

};
</script>

<style scoped>

.main-container {
    display: flex;
}
.card-style {
    text-align: center;
    margin-top: 10px;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
    border: 1px solid #f0f0f0;
    border-top-color: rgb(240, 240, 240);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(240, 240, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(240, 240, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(240, 240, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}
.flex-container {

        display: flex;
        flex-wrap: wrap;
        margin:-10px;
        align-content: left;
        justify-content: left;
}
.numbers {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  height: 30vh;
  margin-left: 10px;
  min-width: 300px;

}

@media (max-width: 768px) {
  .flex-container, .main-container {
    /* Changes from row to column */
    flex-direction: column;
    width: 100%
  }

  .numbers {
    width: 100%;
    margin-left:auto;
    min-height: 450px;
  }
}
</style>
