<template>
  <apexchart
    type="area"
    :options="chartOptions"
    :series="series"
    height="85%"
  />
</template>

<script>
export default {
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    points: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: () => [],
    },
    seriesName: {
      type: String,
      default: () => [],
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      series: [{
        name: this.seriesName,
        data: this.points,
      }],
      chartOptions: {
        chart: {
          type: 'area',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          show: false,
        },
        labels: this.labels,
        xaxis: {
          type: 'datetime',
          labels: {
            show: false,
          },
        },
        yaxis: {
          opposite: true,
          labels: {
            show: true,
          },
        },
        legend: {
          horizontalAlign: 'left',
        },
      },
    };
  },
};
</script>

<style>

</style>
