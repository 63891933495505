<template>
  <div
    v-loading.lock.fullscreen="busy"
    class="reports-page"
  >
    <div
      v-if="!siteInfo.omit_branding"
      class="header"
    >
      <div class="logo">
        <a
          target="_parent"
          :href="siteDomain"
          rel="noopener"
        >
          <img :src="siteInfo.logo">
        </a>
      </div>
      <div
        v-if="siteInfo.phone"
        class="company-info"
      >
        <div>
          {{ siteInfo.name }}
        </div>
        <div>
          <a :href="phoneHref">{{ siteInfo.phone }}</a>
        </div>
      </div>
    </div>
    <div>
      <iframe
        v-cloak
        id="journeyIframe"
        class="iframe"
        :src="journey_report_url"
      />
    </div>
  </div>
</template>

<script>
import { audience as audienceApi } from '@/adonis-api';

export default {
  data() {
    return {
      busy: true,
      name: '',
      url: '',
      siteInfo: {},
      journey_report_url: '',
    };
  },
  computed: {
    phoneHref() {
      if (this.siteInfo.phone.indexOf('.com') !== -1) {
        return this.siteInfo.phone;
      }
      return this.siteInfo.phone
        ? 'tel:+1' + this.siteInfo.phone.replace(/[^\d]/g, '')
        : '';
    },
    siteDomain() {
      if (!this.siteInfo?.domain) {
        return '/';
      }
      return `${window.location.protocol}//${this.siteInfo.domain}`;
    },
  },

  async created() {
    try {
      const response = await audienceApi.getAudienceJourneyReport(
        this.$route.params.uuid,
      );
      this.name = response.name;
      this.siteInfo = response.site_info;
      this.journey_report_url = response.journey_report_url;
    } catch (e) {
      this.$reportError(e);
    } finally {
      this.busy = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.reports-page {
  margin: 1em;
}
.header {
  display: flex;
  .company-info {
    text-align: right;
  }
}
.iframe {
  border: none;
  position: fixed;
  top: 140px;
  left: 0;
  bottom: 0;
  min-width: 200px;
  width: 100%;
  height: calc(100vh - 140px);
  > iframe {
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
}
.logo {
  flex: 1;
  height: 100%;
  position: relative;
  img {
    height: 40px;
  }
}
</style>
