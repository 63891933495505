import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      default: 'Visits By Hour',
    },
    startHour: {
      type: String,
      default: '8AM', // Default start hour
    },
    endHour: {
      type: String,
      default: '5PM', // Default end hour
    },
  },
  mounted() {
    // Helper function to convert 12-hour format to 24-hour format
    const convertTo24Hour = (time) => {
      let [hours, modifier] = time.match(/(\d+)|AM|PM/gi);
      hours = parseInt(hours, 10);
      modifier = modifier.toUpperCase();

      if (modifier === 'PM' && hours !== 12) hours += 12;
      if (modifier === 'AM' && hours === 12) hours = 0;

      return hours;
    };

    const startHour24 = convertTo24Hour(this.startHour);
    const endHour24 = convertTo24Hour(this.endHour);

    // Filter, group, and aggregate the chartData
    const groupedData = this.chartData.reduce((acc, item) => {
      const hour24 = convertTo24Hour(item.date_hour);
      if (hour24 >= startHour24 && hour24 <= endHour24) { // Use dynamic start and end hours
        acc[item.date_hour] = (acc[item.date_hour] || 0) + parseInt(item.sum, 10);
      }
      return acc;
    }, {});

    // Sort the grouped data by date_hour
    const sortedChartData = Object.keys(groupedData)
      .sort((a, b) => convertTo24Hour(a) - convertTo24Hour(b))
      .map(hour => ({ date_hour: hour, sum: groupedData[hour] }));

    // Extract labels and data from the sorted and aggregated array
    const labels = sortedChartData.map(item => item.date_hour);
    const data = sortedChartData.map(item => item.sum);

    // Find the index of the highest value in the data array
    const maxValueIndex = data.indexOf(Math.max(...data));

    // Set the background colors for each bar
    const backgroundColors = data.map((_, index) => {
      if (index === maxValueIndex) {
        return '#4CAF50'; // Green color for the highest bar
      }
      return '#6899D1'; // Blue color for other bars
    });

    this.renderChart(
      {
        labels,
        datasets: [
          {
            label: this.chartTitle,
            data,
            backgroundColor: backgroundColors,
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: this.chartTitle,
          fontSize: 24,
          fontStyle: 'bold',
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontSize: 16,
            usePointStyle: true,
            boxWidth: 5,
            padding: 20,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                beginAtZero: true,
                autoSkip: true,
                maxTicksLimit: 5,
                callback: function (value, index, values) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
        type: this.horizontal ? 'horizontalBar' : 'bar',
      },
    );
  },
};
