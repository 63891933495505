<template>
  <div class="container">
    <div
      class="banner"
      :style="bannerStyle"
    >
      <!-- Banner content -->
    </div>
    <div class="body-wrapper">
      <div class="content-wrapper">
        <p v-if="reportData.reportconfigs.endPageDescription">
          {{ reportData.reportconfigs.endPageDescription }}
        </p>
        <img
          :src="logoURL"
          alt="Logo"
          class="logo"
          @error="setDefaultImage"
        >
        <div
          v-if="reportData.reportconfigs.reportTitle"
          class="footer-item"
        >
          <span
            class="title"
            :class="orientation"
          >
            {{ reportData.reportconfigs.reportTitle }}
          </span>
        </div>
      </div>
      <div class="footer-item">
        <span v-if="reportData.reportconfigs && reportData.reportconfigs.email">
          <b>Contact Us:</b> <a :href="'mailto:' + reportData.reportconfigs.email">
            {{ reportData.reportconfigs.email }}
          </a>
          <!-- Conditionally render the separator if both email and URL are present -->
          <span v-if="reportData.reportconfigs.reportURL"> | </span>
        </span>
        <!-- Ensure URL is only displayed if it exists -->
        <span v-if="reportData.reportconfigs.reportURL">
          <a :href="reportData.reportconfigs.reportURL">
            {{ reportData.reportconfigs.reportURL }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttributionSummary',
  props: {
    orientation: {
      type: String,
      required: true,
      validator: value => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bannerStyle() {
      const defaultBannerUrl =  'https://osd-v2-backend-development.s3.amazonaws.com/public/attribution-logos/busytrafficbanner.png';
      const bannerUrl = this.reportData.banner_url || defaultBannerUrl;

      return {
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.3)), url(${bannerUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        // height: this.orientation === 'landscape' ? '20vh' : '60vh', // Dynamic height based on orientation
        color: 'white', // Ensuring text is visible on dark backgrounds
      };
    },
    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
}
/* Ensure overall container does not exceed viewport height */
.el-row, .el-col {
  max-height: 100vh;
  overflow: hidden; /* Hide overflow */
}
.banner {
  flex: 0 0 auto; /* Do not grow or shrink, but use the height specified */
  text-align: center;
  padding: 20px;
}

.landscape .banner {
  height: 20vh;
}
.landscape {
  height: 100%;
}
.portrait .banner {
  height: 35vh;
}

.page-content {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 20px;
  margin: 5%;
  font-size: 1.25em;
  background-color: white;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 100px;
}

.footer-item {
  margin: 10px 0;
  text-align: center;
}

.title {
  font-size: 24px;
  display: block;
  margin-bottom: 5px;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.logo {
  display: flex;
  margin: auto;
  max-width: 300px;
  max-height: 70%;
  height: auto;
}

.banner {
  height: 50%;
  max-height: 50%;
}

.body-wrapper {
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-wrapper {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
}
</style>
