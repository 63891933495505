<template>
  <div>
    <svg
      id="heatmap"
      width="960"
      height="600"
    />
  </div>
</template>
<script>
import us from './ustopology.json';
const d3 = require('d3');
const topojson = require('topojson');

export default {
  props: {
    features: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      us: us,
    };
  },
  async mounted() {
    const svg = d3.select('#heatmap');
    const width = +svg.attr('width');
    const height = +svg.attr('height');

    const projection = d3
      .geoAlbersUsa()
      .scale(900)
      .translate([width / 2, height / 2]);

    // Draw the map
    svg
      .attr('fill-opacity', 0.7)
      .selectAll('path')
      .data(topojson.feature(this.us, this.us.objects.states).features)
      .enter()
      .append('path')
      .attr('d', d3.geoPath().projection(projection));

    // Add circles:
    svg
      .selectAll('myCircles')
      .data(this.features.data.splice(0, 25000))
      .enter()
      .append('circle')
      .attr('cx', function(d) {
        return projection([d[0], d[1]])[0];
      })
      .attr('cy', function(d) {
        return projection([d[0], d[1]])[1];
      })
      .attr('r', 4)
      .style('fill', 'orange')
      .attr('stroke', 'orange')
      .attr('stroke-width', 2)
      .attr('fill-opacity', 0.3);
  },
  methods: {},
};
</script>
<style scoped></style>
