<template>
  <div
    class="flex"
    @mouseleave="hover = false"
    @mouseenter="hover = true"
  >
    <div
      @mouseleave="tooltipHover = false"
    >
      <span class="titledChartSum">
        {{ sum.toLocaleString("en-US") }}{{ suffix }}
      </span><br>
      <span
        id="title"
        class="titledChartTitle"
      >{{ title }}</span>
    </div>
    <el-tooltip
      :content="tooltip"
      :popper-options="{'width': '300px'}"
    >
      <i
        v-if="!hover"
        class="el-icon-info topRight"
        style="opacity:0; font-size: 18px;"
      />
      <i
        v-if="hover"
        class="el-icon-info topRight"
        style="font-size: 18px;"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {

  components: {
  },
  props: {
    sum: {
      type: Number,
      default: 0,
    },
    suffix: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Unavailable',
    },
    tooltip: {
      type: String,
      default: 'Unavailable',
    },
    paddingTop: {
      type: Number,
      default: 1.8,
    },
    paddingBottom: {
      type: Number,
      default: 2.7,
    },
    tooltipPosLeft: {
      type: Number,
      default: 48,
    },
    tooltipPosTop: {
      type: Number,
      default: 0,
    },
    tooltipPosMarginLeft: {
      type: Number,
      default: 0,
    },
    minHeight: {
      type: Number,
      default: 150,
    },
    breakpoint: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hover: false,
      tooltipHover: false,
    };
  },
};
</script>

<style>
.topRight {

}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 100%;
}

.el-tooltip__popper {
  width: 300px;
}

.titledNumber {
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
    border: 1px solid #f0f0f0;
    border-top-color: rgb(240, 240, 240);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(240, 240, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(240, 240, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(240, 240, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;}

.titledChartTitle {
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Lato", sans-serif;
}

.titledChartTitle:hover {
    color: #509ee3;
    cursor:pointer;
}

.titledChartSum {
font-size:calc(16px + 1.5vw);

  font-weight: 700;
}

.titledChartSum:hover {
    color: #509ee3;
}

.topRight:hover {
      color: #509ee3;

}
.tooltip:hover {
    color: #509ee3;
}

.titledChart {

}
</style>
