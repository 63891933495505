// BarChart.vue
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      default: 'Visits By Hour',
    },
    backgroundColor: {
      type: String,
      default: '#E99A3E',
    },
  },
  mounted() {
    const sortedChartData = [...this.chartData].sort((a, b) => {
      const regex = /^(\d+)%-/;
      const aMatch = a.group.match(regex);
      const bMatch = b.group.match(regex);
      const aValue = aMatch ? parseInt(aMatch[1], 10) : 0;
      const bValue = bMatch ? parseInt(bMatch[1], 10) : 0;
      return aValue - bValue;
    });

    const labels = sortedChartData.map(item => item.group);
    const data = sortedChartData.map(item => item.headcount);

    const backgroundColor = this.backgroundColor;

    this.renderChart({
      labels,
      datasets: [{
        label: this.chartTitle,
        data,
        backgroundColor: data.map(() => backgroundColor),
      }],
    }, {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          type: 'logarithmic',
          gridLines: {
            drawOnChartArea: false,
            display: false,

          },
          ticks: {
            beginAtZero: false,
            display: false,
            callback: function(value, index, values) {
              // Only show integer tick marks (logarithmic scale can result in non-integer values)
              if (value === 10 || value === 100 || value === 1000 || value === 10000 || value === 100000) {
                return value;
              }
              if (value < 10) {
                return value;
              }
              return null;
            },
          },
        }],
        xAxes: [{
          gridLines: {
            drawOnChartArea: false,
          },
          categoryPercentage: 0.8,
          barPercentage: 0.9,
        }],
      },
      type: this.horizontal ? 'horizontalBar' : 'bar',
    });
  },

};
