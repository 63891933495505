<template>
  <el-container class="container">
    <!-- Title Row -->
    <div
      v-if="reportData.reportconfigs.reportTitle"
      class="title"
    >
      {{ reportData.reportconfigs.reportTitle }}
    </div>

    <img
      v-if="reportData.reportconfigs"
      :src="logoURL"
      class="logo"
      @error="setDefaultImage"
    >

    <!-- Footer Row -->
    <a
      :href="reportData.reportconfigs.reportURL"
      class="url"
    >{{ reportData.reportconfigs.reportURL }}</a>
  </el-container>
</template>

<script>
export default {
  name: 'TitlePage',
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titlePageOptions() {
      return (
        this.reportData?.titlePageOptions ?? { reportTitle: '', reportURL: '' }
      );
    },
    logoURL() {
      return this.reportData?.logoURL ?? '/images/onspot-logo.svg';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script><style scoped>
.container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
}

.el-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 92px;
  font-weight: bold;
}

.logo {
  max-width: 50%;
  height: auto;
  max-height: 50%;
  margin: auto;
}

.url {
  text-align: center;
  color: blue;
  text-decoration: underline;
  margin-bottom: 20px;
}

.title-row,
.logo-row {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 48px;
  }
}
</style>
