import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'labels', 'datasets'],
  mounted() {
    // Calculate cumulative sums
    const cumulativeSum = 0;

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 1000,
        easing: 'easeInOutQuad',
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: true, // Show grid lines
            color: 'rgba(0, 0, 0, 0.1)', // Set grid line color
          },
          ticks: {
            fontColor: 'rgba(0, 0, 0, 0.7)',
          },
        }],
        yAxes: [{
          gridLines: {
            display: true, // Show grid lines
            color: 'rgba(0, 0, 0, 0.1)', // Set grid line color
          },
          ticks: {
            fontColor: 'rgba(0, 0, 0, 0.7)',
            autoSkip: true,
            maxTicksLimit: 5,
            callback: function(value, index, values) {
              return value.toLocaleString(); // Format y-axis labels with commas
            },
          },
        }],
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 16,
          usePointStyle: true,
          boxWidth: 5,
          padding: 20,
        },
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.yLabel.toLocaleString(); // Format the tooltip value
            return label;
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
    };

    // Prepare chart labels and dataset
    const chartLabels = this.chartData.map(item => item.date_hour);
    const chartDataset = [{
      label: 'Cumulative Sum',
      data: this.chartData.map(item => item.sum),
      backgroundColor: 'rgba(104, 153, 209, 0.4)',
      borderColor: 'rgba(104, 153, 209, 1)',
      borderWidth: 1,
      pointBackgroundColor: 'rgba(104, 153, 209, 1)',
      pointBorderColor: 'rgba(104, 153, 209, 1)',
      pointHoverBackgroundColor: 'rgba(104, 153, 209, 0.8)',
      pointHoverBorderColor: 'rgba(104, 153, 209, 1)',
      fill: true,
    }];

    // Use the prepared labels and dataset to render the chart
    this.renderChart({ labels: chartLabels, datasets: chartDataset }, options);
  },
};
