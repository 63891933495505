<template>
  <apexchart
    height="400"
    width="500"
    type="line"
    :options="chartOptions"
    :series="data"
  />
</template>

<script>
export default {
  components: {
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: String,
      default: 'Unavailable',
    },
    title: {
      type: String,
      default: 'Unavailable',
    },
    tooltipPosLeft: {
      type: Number,
      default: 48,
    },
    tooltipPosTop: {
      type: Number,
      default: -8.5,
    },
    tooltipPosMarginLeft: {
      type: Number,
      default: -1.5,
    },
  },
  data() {
    return {
      data: [
        {
          name: 'Total Visits',
          data: this.series.map(visit => visit.visits),
        },
      ],
      hover: false,
      tooltipHover: false,
      chartOptions: {
        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.series.map(date => date.date_day),
          title: {
            text: 'Date',
          },
        },
        yaxis: {
          title: {
            text: 'Visits',
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
};
</script>

<style scoped>
.card-style {
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
    border: 1px solid #f0f0f0;
    border-top-color: rgb(240, 240, 240);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(240, 240, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(240, 240, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(240, 240, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

 .titleChart {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63);
    margin-left: 10px;
    }
</style>
