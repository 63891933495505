<template>
  <div
    style="height: 100%"
    @mouseleave="hover = false"
    @mouseenter="hover = true"
  >
    <div
      style="float: left; margin-left: 10px; height: 3%"
      class="titleChart"
    >
      {{ title }}
      <el-tooltip
        :content="tooltip"
        :popper-options="{ width: '300px' }"
      >
        <i
          v-if="!hover"
          class="el-icon-info topRight"
          style="opacity: 0; font-size: 18px"
        />
        <i
          v-if="hover"
          class="el-icon-info topRight"
          style="font-size: 18px"
        />
      </el-tooltip>
    </div>
    <div
      v-show="display"
      :id="id"
      style="width: 100%; height: 90%"
    />
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
  name: 'BaseMap',
  props: {
    id: {
      type: String,
      default: 'map',
    },
    series: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Unavailable',
    },
    tooltip: {
      type: String,
      default: 'Unavailable',
    },
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      map: null,
      hover: false,
      resized: false,
      display: false,
      unformattedGeoJson: this.series,
      mapOptions: {
        container: this.id,
        style: 'mapbox://styles/mapbox/streets-v11',
        attributionControl: false,
        center: [-97.919, 39],
        zoom: 3,
        maxZoom: 10,
        cluster: true,
      },
    };
  },
  created() {
    this.eventBus.$on('attribution-tab-clicked', () => {
      setTimeout(() => {
        this.map.resize();
      }, 60);
    });
  },

  async mounted() {
    const that = this;
    that.display = true;
    mapboxgl.accessToken = that.accessToken;

    that.map = new mapboxgl.Map(that.mapOptions);

    const nav = new mapboxgl.NavigationControl();
    that.map.addControl(nav, 'top-right');
    this.unformattedGeoJson = that.series.map((location) => ({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: [
          parseFloat(location.longitude),
          parseFloat(location.latitude),
        ],
      },
    }));
    const geojson = {
      type: 'FeatureCollection',
      features: this.unformattedGeoJson,
    };
    that.map.on('load', () => {
      that.map.addSource('locations', {
        type: 'geojson',
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });
      that.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'locations',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });
      that.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'locations',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });
      that.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'locations',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      // inspect a cluster on click
      that.map.on('click', 'clusters', (e) => {
        const features = that.map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        that.map
          .getSource('earthquakes')
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            that.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      that.map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
      });
      that.map.on('mouseenter', 'clusters', () => {
        that.map.getCanvas().style.cursor = 'pointer';
      });
      that.map.on('mouseleave', 'clusters', () => {
        that.map.getCanvas().style.cursor = '';
      });
    });
  },
  methods: {},
};
</script>

<style scoped>
.card-style {
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
  border: 1px solid #f0f0f0;
  border-top-color: rgb(240, 240, 240);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(240, 240, 240);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(240, 240, 240);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(240, 240, 240);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}
.titleChart {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 900;
  fill: rgb(55, 61, 63);
  margin-left: 10px;
}
</style>
