<template>
  <div class="container">
    <div class="header">
      <h1>Audience Demographic Profile</h1>
      <p v-if="reportData.reportconfigs.demographicDescription">
        <span>
          <h4>
            {{ reportData.reportconfigs.demographicDescription }}
          </h4>
        </span>
      </p>
    </div>
    <div
      v-if="reportData.reportData"
      class="outer-chart-container"
    >
      <div class="chart-container">
        <div class="box">
          <div class="title">
            Age
          </div>
          <responders-bar-chart
            :chart-data="reportData.reportData.age"
            :horizontal="false"
            style="height: 80%; width: 100%"
          />
        </div>
        <div class="box">
          <div class="title">
            Household Income Distribution
          </div>
          <responder-horizontal-bar
            :household-income="reportData.reportData.householdIncome"
            style="height: 80%; width: 100%"
          />
        </div>
      </div>
      <div class="chart-container second-container">
        <div class="box">
          <div class="title">
            Children at Home
          </div>
          <responder-doughnut-chart
            :chart-data="reportData.reportData.presenceOfChildren"
            style="height: 80%; width: 100%"
          />
        </div>
        <div class="box">
          <div class="title">
            Marital Status
          </div>
          <responder-doughnut-chart
            :chart-data="reportData.reportData.maritalStatus"
            style="height: 80%; width: 100%"
          />
        </div>
        <div class="box dwell-time">
          <div class="title">
            Dwelling Type
          </div>
          <responder-doughnut-chart
            :chart-data="reportData.reportData.dwellingType"
            :chart-type="'doughnut'"
            style="height: 80%; width: 100%"
          />
        </div>
      </div>
      <div class="chart-container third-container">
        <div class="box">
          <gender-chart
            :gender="reportData.reportData.gender"
            style="height: 100%; width: 100%"
          />
        </div>
        <div class="box outer-box">
          <div class="title">
            Education
          </div>
          <responder-pie-chart
            :chart-data="reportData.reportData.education"
            style="height: 80%; width: 100%"
          />
        </div>
      </div>
      <!-- Footer -->
      <el-footer class="footer">
        <div class="footer-content">
          <img
            :src="logoURL"
            alt="Logo"
            class="logo"
            @error="setDefaultImage"
          >
        </div>
      </el-footer>
    </div>
  </div>
</template>

<script>
import RespondersBarChart from '@/components/attribution/reports/report-components/RespondersBarChart';
import GenderChart from '@/components/attribution/reports/report-components/GenderChart.vue';
import ResponderHorizontalBar from '@/components/attribution/reports/report-components/ResponderHorizontalBar';
import ResponderDoughnutChart from '@/components/attribution/reports/report-components/ResponderDoughnutChart';
import ResponderPieChart from '@/components/attribution/reports/report-components/ResponderPieChart';
export default {
  components: {
    RespondersBarChart,
    GenderChart,
    ResponderHorizontalBar,
    ResponderDoughnutChart,
    ResponderPieChart,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logoURL() {
      return this.reportData?.logoURL ?? '/images/onspot-logo.svg';
    },
  },
  mounted() {},
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 100%;
  height: 100%;
  * {
    margin: 0;
  }
}

.header {
  height: 7%;
  margin-bottom: 10px; /* Gap between header and main container */

}

.outer-chart-container {
  background-color: lightgrey;
  height: 90%;
  max-height: 90%;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chart-container {
    display: flex;
    justify-content: space-between;
    height: 37%;
  }

  .second-container {
    height: 24%;

    .box {
      width: 24%;
    }

    .dwell-time {
      width: 49%;
    }
  }

  .third-container {
    height: 32%;
  }

  .box {
    background-color: white;
    border-radius: 8px;
    height: 100%;
    width: 49%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      font-size: 24px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.footer-text {
  margin: 0;
  color: gray;
  font-weight: bold;
  font-size: 20px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5% !important;
  position: relative;
}

.footer-content {
  display: flex;
  align-items: center;
}
.logo {
  max-width: 100px;
  max-height: 50px;
  height: auto;
  position: absolute;
  right: 45px;
}
</style>
