<template>
  <div
    id="chart"
    style="height:100%"
    :series="dataSeries"
    @mouseleave="hover = false"
    @mouseenter="hover = true"
  >
    <apexchart
      type="bar"
      :options="chartOptions"
      height="100%"
      :series="dataSeries"
    />
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    maxHeight: {
      type: Number,
      default: () => 250,

    },
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: String,
      default: 'Unavailable',
    },
    title: {
      type: String,
      default: 'Unavailable',
    },
    tooltipPosLeft: {
      type: Number,
      default: 48,
    },
    tooltipPosTop: {
      type: Number,
      default: -8.5,
    },
    tooltipPosMarginLeft: {
      type: Number,
      default: -1.5,
    },
    minHeight: {
      type: Number,
      default: 150,
    },
    breakpoint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
      tooltipHover: false,
      dataSeries: [
        {
          name: 'Response Rate',
          data: this.series,
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        colors: ['#509DE2', '#F9D45C'],
        dataLabels: { enabled: false },
        responsive: [{
          breakpoint: 1200,
          options: {
            legend: {
              show: false,
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
            xaxis: {
              labels: {
                show: false,
              },
            },
          },
        }],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        series: [{
          data: this.series,
        }],
        xaxis: {
          type: 'category',
          categories: this.categories,
          labels: {
            style: {
              fontSize: '13px',
              fontWeight: 500,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
        },

        yaxis: {
          show: true,
          labels: {
            formatter: (value) => value.toFixed(0) + '%',
          },
        },
        grid: {
          show: false,
        },
        legend: {
          position: 'top',
          markers: {
            radius: 12,
          },
          horizontalAlign: 'left',
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
        },
      },
    };
  },
};
</script>

<style>

</style>
