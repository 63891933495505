<template>
  <div>
    <div class="flex-grid">
      <div class="flex-col flex-grow-1">
        <div class="numbers card-style">
          <titled-number-chart
            :title="data.campaign_response_rate.label"
            :sum="+data.campaign_response_rate.sum"
            :tooltip="data.response_uniques.tooltip"
            :tooltip-pos-top="0"
            :breakpoint="breakpoint"
            :suffix="'%'"
          />
        </div>
      </div>
      <div class="flex-col flex-grow-1">
        <div class="numbers card-style">
          <titled-number-chart
            :title="data.response_uniques.label"
            :sum="+data.response_uniques.sum"
            :tooltip="data.response_uniques.tooltip"
            :tooltip-pos-top="0"
            :breakpoint="breakpoint"
          />
        </div>
      </div>
      <div class="flex-col flex-grow-1">
        <div class="numbers card-style">
          <titled-number-chart
            :title="data.devices_served.label"
            :sum="+data.devices_served.sum"
            :tooltip="data.devices_served.tooltip"
            :breakpoint="breakpoint"
          />
        </div>
      </div>
      <div class="flex-col flex-grow-4">
        <div
          class="card-style area-chart"
          @mouseleave="cumulativeHover = false"
          @mouseenter="cumulativeHover = true"
        >
          <div class="titleChartStacked">
            <div
              id="title"
              style="width: 20em;display:flex;margin-left:10px;"
            >
              {{ data.cumulative_response.label }}
              <el-tooltip
                :content="data.cumulative_response.tooltip"
                :popper-options="{'width': '300px'}"
              >
                <i
                  v-if="!cumulativeHover"
                  class="el-icon-info topRight"
                  style="opacity:0; font-size: 18px;"
                />
                <i
                  v-if="cumulativeHover"
                  class="el-icon-info topRight"
                  style="font-size: 18px;opacity: 1;"
                />
              </el-tooltip>
            </div>
          </div>
          <area-chart
            :labels="data.cumulative_response.labels"
            :points="data.cumulative_response.points"
            :name="data.cumulative_response.label"
            :series-name="'Responses'"
          />
        </div>
      </div>
    </div>
    <div class="flex-grid ">
      <div
        class="flex-col flex-grow-1"
      >
        <div
          class="card-style numbers"
          style="height: 300px;"
        >
          <titled-number-chart
            :title="data.total_visits.label"
            :sum="data.total_visits.sum"
            :tooltip="data.total_visits.tooltip"
            :breakpoint="breakpoint"
          />
        </div>
      </div>
      <div class="flex-col flex-grow-2">
        <div
          class="card-style"
          style="padding-bottom: 40px;height: 300px;"
          @mouseleave="stackHover = false"
          @mouseenter="stackHover = true"
        >
          <div class="titleChartStacked">
            <div
              id="title"
              style="width: 20em;display:flex;margin-left:10px;"
            >
              {{ data.visits_by_weekday.label }}
              <el-tooltip
                :content="data.visits_by_weekday.tooltip"
                :popper-options="{'width': '300px'}"
              >
                <i
                  v-if="!stackHover"
                  class="el-icon-info topRight"
                  style="opacity:0; font-size: 18px;"
                />
                <i
                  v-if="stackHover"
                  class="el-icon-info topRight"
                  style="font-size: 18px;"
                />
              </el-tooltip>
            </div>
          </div>
          <stacked-bar
            v-if="data.visits_by_weekday.catagories"
            :series="data.visits_by_weekday.series"
            :categories="data.visits_by_weekday.catagories"
            :tooltip="data.visits_by_weekday.tooltip"
            :title="data.visits_by_weekday.label"
          />
        </div>
      </div>
      <div class="flex-col flex-grow-1">
        <div
          class="card-style"
          style="height: 291px;min-width: 200px;"
        >
          <map-chart
            :series="data.visits_by_household.series"
            title="Visits by Household"
            :tooltip="data.visits_by_household.tooltip"
          />
        </div>
      </div>
    </div>
    <div class="flex-grid">
      <div
        class="flex-col flex-grow-1"
      >
        <div
          class="card-style"
          style="height: 100%;"
        >
          <table-chart
            :headers="[{'label': 'Name', 'col': 'Name', 'col_size': '75%', 'font_size': '12px'}
                       , {'label': 'Audience Size', 'col': 'Audience Size', 'col_size': '15%', 'text_align': 'right'},
                       {'label': 'Responses', 'col': 'Responses', 'col_size': '10%', 'text_align': 'right'}]"
            :series="data.responses_by_audience.series"
            :title="data.responses_by_audience.label"
            :page-size="7"
            :tooltip="data.responses_by_audience.tooltip"
            :apply-heated-col="false"
            :progress-bar="true"
            :progress-bar-col="'Audience Size'"
          />
        </div>
      </div>

      <div
        class="flex-col flex-grow-1"
      >
        <div
          class="card-style"
          style="height: 100%;"
        >
          <table-chart
            :headers="[{'label': 'City', 'col': 'city'}, {'label': 'State', 'col': 'state'}, {'label': 'Responses', 'col': 'sum', 'text_align': 'right'}]"
            :series="data.responses_by_city.series"
            :title="data.responses_by_city.label"
            :tooltip="data.responses_by_city.tooltip"
            :page-size="8"
            :apply-heated-col="true"
          />
        </div>
      </div>

      <div class="flex-col flex-grow-1">
        <div
          class="card-style"
          style="height: 100%;"
          @mouseleave="responsesByHourHover = false"
          @mouseenter="responsesByHourHover = true"
        >
          <div class="titleChartStacked">
            <div
              id="title"
              style="width: 20em;display:flex;margin-left:10px;"
            >
              {{ data.responses_by_hour.label }}
              <el-tooltip
                :content="data.responses_by_hour.tooltip"
                :popper-options="{'width': '300px'}"
              >
                <i
                  v-if="!responsesByHourHover"
                  class="el-icon-info topRight"
                  style="opacity:0; font-size: 18px;"
                />
                <i
                  v-if="responsesByHourHover"
                  class="el-icon-info topRight"
                  style="font-size: 18px;opacity: 1;"
                />
              </el-tooltip>
            </div>
          </div>
          <bar-chart
            :series="data.responses_by_hour.series"
            :tooltip="data.responses_by_hour.tooltip"
            :title="data.responses_by_hour.label"
            :categories="data.responses_by_hour.categories"
          />
        </div>
      </div>
    </div>
    <div class="flex-grid">
      <div
        class="flex-col flex-grow-1"
      >
        <div
          class="card-style"
          style="height: 350px; margin-top: 20px;"
          @mouseleave="stackAreaHover = false"
          @mouseenter="stackAreaHover = true"
        >
          <div class="titleChartStacked">
            <div
              id="title"
              style="width: 20em;display:flex;margin-left:10px;"
            >
              {{ data.initial_and_repeat_visits.label }}
              <el-tooltip
                :content="data.initial_and_repeat_visits.tooltip"
                :popper-options="{'width': '300px'}"
              >
                <i
                  v-if="!stackAreaHover"
                  class="el-icon-info topRight"
                  style="opacity:0; font-size: 18px;"
                />
                <i
                  v-if="stackAreaHover"
                  class="el-icon-info topRight"
                  style="font-size: 18px;opacity: 1;"
                />
              </el-tooltip>
            </div>
          </div>
          <stacked-area-chart
            :series-data="data.initial_and_repeat_visits.series"
            :name="data.initial_and_repeat_visits.label"
            :labels="data.initial_and_repeat_visits.labels"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitledNumberChart from '../charts/TitledNumber.vue';
import StackedBar from '../charts/StackedBar.vue';
import TableChart from '../charts/TableChart.vue';
import MapChart from '../charts/MapChart.vue';
import AreaChart from '../charts/AreaChart.vue';
import BarChart from '../charts/BarChart.vue';
import StackedAreaChart from '../charts/StackedAreaChart.vue';

export default {
  name: 'AttributionFootfallDashboard',
  components: {
    TitledNumberChart,
    StackedBar,
    MapChart,
    TableChart,
    AreaChart,
    BarChart,
    StackedAreaChart,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      stackHover: false,
      stackAreaHover: false,
      responsesByHourHover: false,
      cumulativeHover: false,
    };
  },
  computed: {
    breakpoint() {
      return this.windowWidth >= 768;
    },
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },

};
</script>

<style>

.numbers {
  min-width: 220px;
}
.flex-grid {
  display: flex;
  align-items: stretch;
}

.flex-col {
  flex: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 3;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.topRight::hover {
      color: #509ee3;

}
.area-chart {
  height: 15vw;
  min-width: 250px;
  margin-top: -35px;

}

.card-style {
    text-align: center;
    margin-top: 10px;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
    border: 1px solid #f0f0f0;
    border-top-color: rgb(240, 240, 240);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(240, 240, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(240, 240, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(240, 240, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

  .titleChartStacked {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63);
    margin-right: 80%;
    margin-top: 15px;
    }

@media (max-width: 768px) {
  .flex-grid {
    flex-direction: column;
  }

  .area-chart {
    width: 100%;
    height: 300px;
    margin-left: auto;
  }
  .numbers {
    width: 100%;
    margin-left:auto;
    min-height: 450px;
  }
}
</style>
