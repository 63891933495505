<template>
  <div
    v-loading.lock.fullscreen="busy"
    class="reports-page"
  >
    <div
      v-if="!siteInfo.omit_branding"
      class="header"
    >
      <div class="logo">
        <a
          target="_parent"
          :href="siteDomain"
          rel="noopener"
        >
          <img :src="siteInfo.logo">
        </a>
      </div>
      <div
        v-if="siteInfo.phone"
        class="company-info"
      >
        <div>
          {{ siteInfo.name }}
        </div>
        <div>
          <a :href="phoneHref">{{ siteInfo.phone }}</a>
        </div>
      </div>
    </div>
    <div class="title">
      <h2>
        {{ name }} Audience Reports
      </h2>
    </div>
    <div class="tabs-wrapper">
      <help-tip
        v-if="insightReport"
        ref="insightTip"
        class="tab-tip"
      >
        This audience report contains spatio-temporal and demographic data for
        the devices captured within the selected geoframes. Note that the counts
        used throughout are based on unique Mobile Ad IDs (MAID) and in many
        cases are measuring the quantity of visits by those MAIDs; a single MAID
        can make more than one visit to polygons during the geoframes’ time
        window.
      </help-tip>
      <help-tip
        v-if="demographicReport"
        ref="demographicTip"
        class="tab-tip"
      >
        This audience report contains key demographic elements of the devices
        captured within the selected geoframes. More demographic attributes are
        available for this audience and can be downloaded via the Complete
        Demographics CSV report.
      </help-tip>
      <help-tip
        v-if="heatmapReport"
        ref="heatmapTip"
        class="tab-tip"
      >
        Visual representation of the audience’s household locations using the
        devices for the selected geoframes. This displays a subset of total
        devices observed as not  all devices were matched to a household
        address. Counts may vary and not necessarily reconcile with the actual
        audience number. You can change between cluster and heatmap view by
        clicking the pie chart icon in the top right.
      </help-tip>
      <help-tip
        v-if="politicalReport"
        ref="politicalTip"
        class="tab-tip"
      >
        This audience report contains political probability and prediction data of the given audience devices.
      </help-tip>
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-if="insightReport"
          label="Insight Report"
          name="insights"
        >
          <div
            ref="insights"
            class="iframe"
          >
            <iframe
              width="100%"
              height="100%"
              :src="insightReport.url_iframe"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-if="demographicReport"
          label="Demographic Report"
          name="demographic"
        >
          <div
            ref="demographic"
            class="iframe"
          >
            <iframe
              width="100%"
              height="100%"
              :src="demographicReport.url_iframe"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-if="heatmapReport"
          label="Heatmap Explorer"
          name="heatmap"
        >
          <div
            ref="heatmap"
            class="iframe"
          >
            <iframe
              width="100%"
              height="100%"
              :src="heatmapReport.url_iframe"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-if="politicalReport"
          label="Political Report"
          name="political"
        >
          <div
            ref="political"
            class="iframe"
          >
            <iframe
              width="100%"
              height="100%"
              :src="politicalReport.url_iframe"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import HelpTip from '../../global/HelpTip.vue';
import { audience as audienceApi } from '@/adonis-api';

export default {
  components: { HelpTip },
  data() {
    return {
      activeTab: '',
      busy: true,
      name: '',
      reports: [],
      siteInfo: {},
    };
  },

  computed: {
    insightReport() {
      return this.reports.find(r => r.type === 'insights');
    },

    demographicReport() {
      return this.reports.find(r => r.type === 'demographic');
    },

    heatmapReport() {
      return this.reports.find(r => r.type === 'heatmap');
    },

    phoneHref() {
      if (this.siteInfo.phone.indexOf('.com') !== -1) {
        return this.siteInfo.phone;
      }
      return this.siteInfo.phone
        ? 'tel:+1' + this.siteInfo.phone.replace(/[^\d]/g, '')
        : '';
    },
    politicalReport() {
      return this.reports.find(r => r.type === 'political');
    },
    siteDomain() {
      if (!this.siteInfo?.domain) {
        return '/';
      }
      return `${window.location.protocol}//${this.siteInfo.domain}`;
    },
  },

  watch: {
    activeTab(value) {
      this.$nextTick(() => {
        if (!this.$refs[value]) return;
        const { top } = this.$refs[value].getBoundingClientRect();
        // this.$refs[value].style.height = `${window.innerHeight - top - 56}px`;
        this.$refs[value].style.height = `${window.innerHeight - top}px`;
      });
    },

    reports(value) {
      if (value.length > 0) {
        setTimeout(() => {
          const insightTab = this.$el.querySelector('#tab-insights');
          const demographicTab = this.$el.querySelector('#tab-demographic');
          const heatmapTab = this.$el.querySelector('#tab-heatmap');
          const politicalTab = this.$el.querySelector('#tab-political');
          const adjustTip = (tabEl, tipName) => {
            const { right } = tabEl.getBoundingClientRect();
            const leftPos = parseInt(right) - (tabEl.nextSibling ? 35 : 16);

            this.$refs[tipName].$el.style.left = `${leftPos}px`;
            this.$refs[tipName].$el.style.display = 'block';
          };

          if (insightTab) {
            adjustTip(insightTab, 'insightTip');
          }
          if (demographicTab) {
            adjustTip(demographicTab, 'demographicTip');
          }
          if (heatmapTab) {
            adjustTip(heatmapTab, 'heatmapTip');
          }
          if (politicalTab) {
            adjustTip(politicalTab, 'politicalTip');
          }
        }, 500);
      }
    },
  },

  async created() {
    try {
      const response = await audienceApi.getReportUrls(
        this.$route.params.hashId,
      );
      this.name = response.name;
      this.siteInfo = response.site_info;
      this.reports = response.report_urls;
      this.activeTab = this.reports[0].type;
    } catch (e) {
      // bad
    } finally {
      this.busy = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.reports-page {
  margin: 1em 1em 0 1em;
}

.header {
  display: flex;

  .company-info {
    text-align: right;
  }
}

.iframe {
  position: fixed;
    top: 170px;
    left: 0;
    bottom: 0;
    min-width: 200px;
    width: 100%;
    height: calc(100vh - 170px);

  > iframe {
    border: none;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
}

.logo {
  flex: 1;
  height: 100%;
  position: relative;

  img {
    height: 40px;
  }
}

.tabs-wrapper {
  position: relative;
}

.tab-tip {
  display: none;
  position: absolute;
  top: 2px;
  z-index: 10;
}
</style>
