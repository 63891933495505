<template>
  <div class="wrapper">
    <div class="outer-container demoGraphic">
      <div class="header">
        <h1>Audience Demographic Profile</h1>
        <p v-if=" reportData.reportconfigs.demographicDescription ">
          <span><h4>{{ reportData.reportconfigs.demographicDescription }}</h4></span>
        </p>
      </div>
      <el-container class="main-container">
        <!-- First Row with 2 Columns -->
        <el-row
          :gutter="20"
          type="flex"
          style="margin-right: 0px !important;"
        >
          <!-- Column 1 -->
          <el-col
            :span="24"
            :xs="24"
          >
            <div class="box seniorAndVeterans">
              <seniors-and-veterans
                :veterans-present="reportData.reportData.veteransPresent[0].headcount"
                :seniors-present="reportData.reportData.seniorsPresent[0].headcount"
              />
            </div>
          </el-col>
        </el-row>

        <!-- Second Row with 2 Columns -->
        <el-row
          :gutter="20"
          style="margin-right: 0px !important;padding-top: 10px;"
        >
          <el-col
            :span="8"
            :xs="24"
          >
            <div class="box">
              <top-table
                style="height: 100%; padding: 0;"
                :data="reportData.reportData.charity"
              />
            </div>
          </el-col>
          <!-- Column 1 -->
          <el-col
            :span="8"
            :xs="24"
          >
            <div class="box">
              <top-table
                style="height: 100%; padding: 0;"
                :data="reportData.reportData.interests"
                title="Lifestyle - Top 5 Interests"
              />
            </div>
          </el-col>
          <!-- Column 2 -->
          <el-col
            :span="8"
            :xs="24"
          >
            <div class="box">
              <top-table
                style="height: 100%; padding: 0;"
                title="Lifestyle - Top 5 Media Usage"
                :data="reportData.reportData.mediaUsage"
              />
            </div>
          </el-col>
        </el-row>

        <el-footer class="footer">
          <div class="footer-content">
            <img
              :src="logoURL"
              alt="Logo"
              class="logo"
              @error="setDefaultImage"
            >
          </div>
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>

import SeniorsAndVeterans from '../customComponents/SeniorsAndVeterans.vue';
import TopTable from '../customComponents/TopTable.vue';
export default {
  components: {
    SeniorsAndVeterans,
    TopTable,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logoURL() {
      return this.reportData?.logoURL ?? '/images/onspot-logo.svg';
    },
  },
  mounted() {
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },

};
</script>

<style>
.demoGraphic .top-table .cell {
  padding: 0 !important;
}
</style>

<style scoped>
.wrapper {
  height: 100%;
}
.outer-container {
  width: 100%;
  height: 100%;
}

.header {
  background-color: white;
  padding: 0;
  margin-bottom: 10px; /* Gap between header and main container */
  height: 10%;
}

.header h1 {
  margin: 0
}

.header-title,
.header-description {
  margin-left: 40px;
}

.main-container {
  background-color: lightgrey;
  height: 90%;
  max-height: 90%;
  gap: 12px !important;
  padding: 12px !important;
  justify-content: space-between;
  border-radius: 8px;
}
.el-row {
  background-color: lightgrey;
  margin-left: 0px !important;
  height: 78% !important;
}

.el-row:nth-child(1) {
  height: 10% !important;
}

.el-col {
  height: 100% !important;
}

.box {
  background-color: white;
  padding: 12px;
  height: 90%;
  border-radius: 8px;
}

.map-box .column5-box {
  height: 90%;

}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10% !important;
  position: relative;
}

.footer-content {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100px;
  max-height: 50px;
  height: auto;
  position: absolute;
  right: 45px;
}

.footer-text {
  margin: 0;
  color: gray;
  font-weight: bold;
  font-size: 20px;
}

.box .data {
  font-size: 36px;
  font-weight: bold;
}
.box .title {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .description {
  font-size: 18px;
}

.box hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Media query for tablets */
@media (max-width: 768px) {
  .el-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .box {
    height: auto;
    margin-bottom: 10px;
  }
}

/* Media query for phones */
@media (max-width: 576px) {
  .el-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .box {
    height: auto;
  }
}
</style>
