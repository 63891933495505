<template>
  <svg
    width="700"
    height="300"
  />
</template>

<script>
const d3 = require('d3');
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    colorStep: {
      type: Number,
      default: 0,
    },
  },
  mounted: function() {
    const svg = d3.select(this.$el);
    const width = +svg.attr('width');
    const height = +svg.attr('height');
    const margin = { top: 20, left: 0, bottom: 30, right: 0 };
    const chartWidth = width - (margin.left + margin.right);
    const chartHeight = height - (margin.top + margin.bottom);
    this.chartLayer = svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);
    this.arc = d3
      .arc()
      .outerRadius(chartHeight / 2)
      .innerRadius(chartHeight / 4)
      .cornerRadius(1);
    this.pieG = this.chartLayer
      .append('g')
      .attr('transform', `translate(${chartWidth / 2}, ${chartHeight / 2})`);
    this.drawChart(this.formatData(this.data));
  },
  methods: {
    formatData(data) {
      let colorNum = 0.1;
      for (const set in data) {
        data[set].color = colorNum;
        colorNum += this.colorStep;
      }
      return data;
    },

    drawChart: function(data) {
      const arcs = d3
        .pie()
        .sort(null)
        .value(function(d) {
          return d.headcount;
        })(data);

      const block = this.pieG.selectAll('.arc').data(arcs);
      block.select('path').attr('d', this.arc);
      const newBlock = block
        .enter()
        .append('g')
        .classed('arc', true);
      newBlock
        .append('path')
        .attr('d', this.arc)
        .attr('id', function(d, i) {
          return 'arc-' + i;
        })
        .attr('stroke', 'black')
        .attr('fill', d => {
          return d3.interpolateViridis(d.data.color);
        });
      d3.select(this.$el)
        .selectAll('legendDots')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', 450)
        .attr('cy', function(d, i) {
          return 15 + i * 25;
        })
        .attr('r', 7)
        .style('fill', d => {
          return d3.interpolateViridis(d.color);
        });
      d3.select(this.$el)
        .selectAll('legendLabls')
        .data(data)
        .enter()
        .append('text')
        .attr('x', 465)
        .attr('y', function(d, i) {
          return 15 + i * 25;
        })
        .style('fill', function(d) {
          return d3.interpolateViridis(d.color);
        })
        .text(function(d) {
          return d.group + ' - ' + d.headcount.toLocaleString();
        })
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle');
    },
  },
};
</script>
