<template>
  <div class="wrapper">
    <div class="outer-container">
      <div class="header">
        <h1>Audience Political Profile</h1>
        <p v-if=" reportData.reportconfigs.politicalDescription ">
          <span><h4>{{ reportData.reportconfigs.politicalDescription }}</h4></span>
        </p>
      </div>
      <el-container class="main-container">
        <!-- First Row with 2 Columns -->
        <el-row
          :gutter="20"
          style="margin-right: 0px !important;"
        >
          <!-- Column 1 -->
          <el-col
            :span="12"
            :xs="24"
          >
            <div class="box">
              <div>
                <div class="chart-title">
                  Federal Donor Probability
                </div>
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'federalDonorProbability'"
              />
            </div>
          </el-col>
          <!-- Column 2 -->
          <el-col
            :span="12"
            :xs="24"
          >
            <div class="box">
              <div>
                <div class="chart-title">
                  State Donor Probability
                </div>
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'stateDonorProbability'"
              />
            </div>
          </el-col>
        </el-row>

        <!-- Second Row with 2 Columns -->
        <el-row
          :gutter="20"
          style="margin-right: 0px !important;"
        >
          <!-- Column 1 -->
          <el-col
            :span="12"
            :xs="24"
          >
            <div class="box">
              <div>
                <div class="chart-title">
                  Federal Donation Amount Prediction
                </div>
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'federalDonationAmountPrediction'"
              />
            </div>
          </el-col>
          <!-- Column 2 -->
          <el-col
            :span="12"
            :xs="24"
          >
            <div class="box">
              <div>
                <div class="chart-title">
                  State Donation Amount Prediction
                </div>
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'stateDonationAmountPrediction'"
              />
            </div>
          </el-col>
        </el-row>

        <el-footer class="footer">
          <div class="footer-content">
            <img
              :src="logoURL"
              alt="Logo"
              class="logo"
              @error="setDefaultImage"
            >
          </div>
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>
import ChartWithModal from '../customComponents/ChartWithModal.vue';
export default {
  components: {
    ChartWithModal,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logoURL() {
      return this.reportData?.logoURL ?? '/images/onspot-logo.svg';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100%;
}
.chart-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.outer-container {
  height: 100%;
  width: 100%;
}

.header {
  background-color: white;
  padding: 0;
  margin-bottom: 10px; /* Gap between header and main container */
  height: 10%;
}

.header h1 {
  margin: 0
}

.header-title,
.header-description {
  margin-left: 40px;
}
.main-container {
  background-color: lightgrey;
  display: flex; /* Make the main container a flex container */
  flex-direction: column;
  height: 90%;
  max-height: 90%;
  gap: 12px !important;
  padding: 12px !important;
  justify-content: space-between;
  border-radius: 8px;
}

.el-row {
  height: 47% !important;
  display: flex;
  gap: 12px;
}

.el-col {
  height: 100% !important;
  padding: 0 !important;
}

.box {
  background-color: white;
  /* margin: 12px; */
  flex: 1; /* Make boxes flex items with flex-grow */
  display: flex; /* Make each .box a flex container */
  flex-direction: column; /* Stack children of .box vertically */
  justify-content: space-between; /* Distribute space between header and footer */
  height: 90%;
  border-radius: 8px;
  gap: 12px;
  padding: 12px;
}

.map-box .column5-box {
  height: 90%;

}
.second-row {
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 5% !important;
}

.footer-content {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100px;
  max-height: 50px;
  height: auto;
  position: absolute;
  right: 45px;
}

.footer-text {
  margin: 0;
  color: gray;
  font-weight: bold;
  font-size: 20px;
}
.box .data {
  font-size: 36px;
  font-weight: bold;
}
.box .title {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box .description {
  font-size: 18px;
}

.box hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Media query for tablets */
@media (max-width: 768px) {
  .el-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .box {
    height: auto;
    margin-bottom: 10px;
  }
}

/* Media query for phones */
@media (max-width: 576px) {
  .el-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .box {
    height: auto;
  }
}

</style>
