<template>
  <div>
    <div class="container-chart text-align-left">
      <div
        :id="name"
        class="chart"
      />
    </div>
  </div>
</template>
<script>
const d3 = require('d3');

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    barLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formattedLabels: [],
      min: null,
      max: null,
    };
  },
  mounted() {
    const that = this;
    d3.select(`#${this.name}`)
      .selectAll('div')
      .data(this.formatData(this.data))
      .enter()
      .append('text')
      .attr('x', 0)
      .attr('y', function(d, i) {
        return 0;
      })
      .style('font-size', '11px')
      .text(function(d, i) {
        return that.data[i].attribute;
      })
      .append('div')
      .style('width', function(d) {
        return (((d - that.min)) / (that.max - that.min)) * 100 + 125 + 'px';
      })
      .style('border-radius', function() { return '5px'; })
      .text(function(d) {
        return d;
      });
  },
  methods: {
    formatData(data) {
      const formattedData = [];
      for (const idx in data) {
        formattedData.push(data[idx].headcount);
        this.formattedLabels.push(data[idx].attribute);
      }
      this.max = formattedData[0];
      this.min = formattedData[4];
      return formattedData;
    },
  },
};
</script>

<style scoped>
.chart div {
  font: 12px sans-serif;
  background-color: steelblue;
  text-align: right;
  padding: 3px;
  margin: 1px;
  color: white;
}
.container-chart {
  /* width: 50%; */
  margin: auto;
}
</style>
