<template>
  <div class="insight-footer-section">
    <img
      :src="logo"
      alt="Logo"
      class="logo"
      @error="setDefaultImage"
    >
  </div>
</template>

<script>
export default {
  name: 'InsightFooterSection',
  props: {
    logoURL: {
      type: String,
      required: true,
    },
  },
  computed: {
    logo() {
      return this.logoURL || '/images/onspot-logo.svg';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style>
.insight-footer-section {
  max-height: 10%;
  display: flex;
  justify-content: end !important;
  align-items: center;
  gap: 12px;
}

.insight-footer-section .logo {
  max-height: 65px;
  max-width: 10%;
  object-fit: contain;
  object-position: center;
}
</style>
