<template>
  <div class="mapContainer">
    <div
      id="heatmap"
      :style="'width: 95%; height: 90%; margin: auto'"
    />
  </div>
</template>

<script>
// import DEMO_GEO_JSON from './geo.json';
import * as d3 from 'd3';

export default {
  name: 'HeatmapPortrait2',
  props: {
    reportData: {
      type: Object,
      required: true,
    },
    heatmapUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      geoJSON: null,
    };
  },
  mounted() {
    const uuid = this.extractUUID(this.heatmapUrl);

    if (uuid && !this.geoJSON) {
      this.fetchHeatmapData(uuid);
    }

    window.addEventListener('resize', () => {
      requestAnimationFrame(this.renderMap);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      requestAnimationFrame(this.renderMap);
    });
  },
  methods: {
    convertToGeoJSON(data) {
      return {
        type: 'FeatureCollection',
        features: data?.map((coords, index) => ({
          type: 'Feature',
          id: index,
          geometry: {
            type: 'Point',
            coordinates: coords,
          },
          properties: {},
        })),
      };
    },
    renderMap(rawData) {
      if (!rawData || !rawData?.length) return;
      const geoJSON = this.convertToGeoJSON(rawData);
      const containerRef = '#heatmap';
      const container = d3.select(containerRef);
      const parent = container.node().parentNode;
      const width = parent.getBoundingClientRect().width;
      const height = parent.getBoundingClientRect().height;
      const reportData = this.reportData.reportData;

      const projection = d3
        .geoAlbersUsa()
        .fitSize([width, height], reportData.states);
      const path = d3.geoPath().projection(projection);

      container.selectAll('*').remove();

      const svg = container
        .append('svg') // Append a new SVG
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMidYMid meet');

      svg
        .append('g')
        .attr('class', 'states')
        .selectAll('path')
        .data(this.reportData.reportData.states.features)
        .enter()
        .append('path')
        .attr('d', path)
        .style('fill', function (d) {
          return 'rgb(231, 237, 222)';
        })
        .attr('stroke', function () {
          return 'black';
        })
        .attr('stroke-width', 1);

      svg
        .append('g')
        .selectAll('circle')
        .data(geoJSON.features)
        .enter()
        .append('circle')
        .attr('cx', (d) => {
          const coords = projection(d.geometry.coordinates);
          return coords ? coords[0] : null;
        })
        .attr('cy', (d) => {
          const coords = projection(d.geometry.coordinates);
          return coords ? coords[1] : null;
        })
        .attr('r', 5) // adjust the radius as needed
        .attr('fill', 'blue') // adjust the color as needed
        .attr('opacity', 0.4);
    },
    extractUUID(url) {
      if (!url) return null;
      const regex =
        /\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})\//;
      const match = url.match(regex);
      return match ? match[1] : null;
    },
    async fetchHeatmapData(uuid) {
      const apiUrl =
        process.env.VUE_APP_ADONIS_ENDPOINT ===
        'https://app-backend-dev.onspotdata.com'
          ? `https://qa.spotchart.onspotdata.com/audience/report/${uuid}/residences?take=5000&select=longitude&select=latitude`
          : `https://prod.spotchart.onspotdata.com/audience/report/${uuid}/residences?take=5000&select=longitude&select=latitude`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        this.geoJSON = data?.data;
        this.renderMap(data?.data);
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.mapContainer {
  width: 100%;
  height: 100%;
  background: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
}
</style>
