<template>
  <div class="main-containers">
    <div>
      <div class="flexer-container">
        <div class="numbers card-style">
          <titled-number-chart
            :title="data.response_uniques.label"
            :sum="Number(data.response_uniques.sum)"
            :tooltip="data.response_uniques.tooltip"
            :tooltip-pos-top="0"
            :breakpoint="breakpoint"
          />
        </div>
        <div class="numbers  card-style">
          <titled-number-chart
            :tooltip-pos-left="breakpoint ? 100 : 100"
            :title="data.total_visits.label"
            :sum="Number(data.total_visits.sum)"
            :tooltip="data.total_visits.tooltip"
            :breakpoint="breakpoint"
          />
        </div>
        <div
          class="stackedbar  card-style"
          style="padding-bottom: 40px;"
          @mouseleave="stackHover = false"
          @mouseenter="stackHover = true"
        >
          <div class="titleChartStacked">
            <div
              id="title"
              style="width: 20em;display:flex;margin-left:10px;"
            >
              {{ data.visits_by_weekday.label }}
              <el-tooltip
                :content="data.visits_by_weekday.tooltip"
                :popper-options="{'width': '300px'}"
              >
                <i
                  v-if="!stackHover"
                  class="el-icon-info topRight"
                  style="opacity:0; font-size: 18px;"
                />
                <i
                  v-if="stackHover"
                  class="el-icon-info topRight"
                  style="font-size: 18px;"
                />
              </el-tooltip>
            </div>
          </div>
          <stacked-bar
            v-if="data.visits_by_weekday.catagories"
            :series="data.visits_by_weekday.series"
            :categories="data.visits_by_weekday.catagories"
            :tooltip="data.visits_by_weekday.tooltip"
            :title="data.visits_by_weekday.label"
            :tooltip-pos-left="100"
            :tooltip-pos-top="-11"
            :breakpoint="breakpoint"
          />
        </div>
      </div>
      <div
        class="flexer-container"
      >
        <div
          class="mapCss  card-style"
          style="width: 100%"
        >
          <map-chart
            id="map2"
            :series="data.visits_by_household.series"
            title="Visits by Household"
            :tooltip="data.visits_by_household.tooltip"
          />
        </div>
        <div
          class="card-style "

          @mouseleave="lineHover = false"
          @mouseenter="lineHover = true"
        >
          <div class="titleChartLine">
            <span
              id="title"
            >{{ data.visits_per_day.label }}</span>
            <el-tooltip
              :content="data.visits_per_day.tooltip"
              :popper-options="{'width': '300px'}"
            >
              <i
                v-if="!lineHover"
                class="el-icon-info topRight"
                style="opacity:0; font-size: 18px;"
              />
              <i
                v-if="lineHover"
                class="el-icon-info topRight"
                style="font-size: 18px;"
              />
            </el-tooltip>
          </div>
          <line-chart
            :series="data.visits_per_day.series"
            :tooltip="data.visits_per_day.tooltip"
            :title="data.visits_per_day.label"
          />
        </div>
        <div class="responsesByCity  card-style">
          <table-chart
            :headers="[{'label': 'City', 'col': 'city'}, {'label': 'State', 'col': 'state'}, {'label': 'Responses', 'col': 'sum', 'text_align': 'right'}]"
            :series="data.responses_by_city.series"
            :title="data.responses_by_city.label"
            :tooltip="data.responses_by_city.tooltip"
            :page-size="8"
          />
        </div>
      </div>
    </div>
    <div
      class="card-style responsesByZip"
      style="width:100%;"
    >
      <table-chart
        :headers="[{'label': 'Zipcode', 'col': 'zipcode', 'col_size': '50%'}, {'label': 'Responses', 'col': 'sum', 'text_align': 'right'}]"
        :series="data.responses_by_zipcode.series"
        :title="data.responses_by_zipcode.label"
        :tooltip="data.responses_by_zipcode.tooltip"
        :page-size="15"
      />
    </div>
  </div>
</template>

<script scoped>
import TitledNumberChart from '../charts/TitledNumber.vue';
import StackedBar from '../charts/StackedBar.vue';
import TableChart from '../charts/TableChart.vue';
import MapChart from '../charts/MapChart.vue';
import LineChart from '../charts/LineChart.vue';
export default {
  name: 'AttributionDigitalDashboard',
  components: {
    TitledNumberChart,
    StackedBar,
    LineChart,
    MapChart,
    TableChart,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      lineHover: false,
      stackHover: false,
    };
  },
  computed: {
    breakpoint() {
      return this.windowWidth >= 768;
    },
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },

};
</script>

<style scoped>

.main-containers {
    display: flex;

}

.card-style {
    text-align: center;
    margin-top: 10px;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
    border: 1px solid #f0f0f0;
    border-top-color: rgb(240, 240, 240);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(240, 240, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(240, 240, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(240, 240, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}
.flexer-container {
  flex: 1;
  box-sizing: border-box;
  margin:0;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  padding: 0;
}

.numbers {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  height: 15vw;
  margin-left: 10px;
  width: 20vw;
  min-width: 250px;
}

.stackedbar {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  height: 15vw;
  width: 40vw;
  min-width: 610px;
}

.topRight:hover {
      color: #509ee3;

}
.mapCss {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 25vw;
  min-width: 300px;
}

.responsesByCity {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 25vw;
}
.responsesByZip {
    justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 30vw;
}
  .titleChartLine {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63);
    margin-right: 60%;
    margin-top: 15px;
    }

  .titleChartStacked {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63);
    margin-right: 70%;
    margin-top: 15px;
    }
  .responsesByAudience {
    justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  width: 30vw;
}
@media (max-width: 768px) {
  .flexer-container {
    /* Changes from row to column */
    flex-direction: column;
    width: 100%;
  }
  .main-containers {
    display: grid;
    width: 100%
  }

  .responsesByZip {
    min-height: 650px;
    width: 100%;
    margin-left:auto;
  }

    .mapCss {
    width: 100%;
    height: 500px;
    margin-left:auto;
    }

  .numbers,
  .responsesByCity,
  .responsesByAudience,
  .stackedbar {
    width: 100%;
    margin-left:auto;
    min-height: 450px;
  }
  .stackedbar {
    width: 100%;
  }
}
</style>
