<template>
  <div class="wrapper">
    <div class="outer-container">
      <div class="header">
        <h1>Audience Insights</h1>
        <p v-if=" reportData.reportconfigs.insightDescription ">
          <span><h4>{{ reportData.reportconfigs.insightDescription }}</h4></span>
        </p>
      </div>
      <el-container class="main-container">
        <!-- First Row with 2 Columns -->
        <el-row
          :gutter="20"
          style="margin-right: 0px !important;"
        >
          <el-col
            :span="16"
            :xs="24"
            style="height: 100%"
          >
            <div
              class="box"
            >
              <div class="title">
                Visitor Frequency
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'visitorFrequency'"
              />
            </div>
          </el-col>
          <el-col
            :span="8"
            :xs="24"
            style="height: 100%"
          >
            <div
              class="box"
            >
              <div class="title">
                Visits by Hour
              </div>
              <!-- Start Hour Dropdown -->
              <chart-with-modal
                :key="componentKey"
                :report-data="reportData"
                :map-type="'visitsByHour'"
                :chart-data="reportData.reportData.visitsByHour"
                :start-hour="selectedStartHour"
                :end-hour="selectedEndHour"
              />
            </div>
          </el-col>
        </el-row>

        <!-- Second Row with 2 Columns -->
        <el-row
          class="second-row"
          :gutter="20"
          style="margin-right: 0px !important;"
        >
          <el-col
            :span="8"
            :xs="24"
            style="height: 100%"
          >
            <div
              class="box"
            >
              <div class="title">
                Visits by Weekday
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'visitsByWeekday'"
              />
            </div>
          </el-col>
          <el-col
            :span="16"
            :xs="24"
            style="height: 100%"
          >
            <div
              class="box"
            >
              <div class="title">
                Cumulative Visits
              </div>
              <chart-with-modal
                :report-data="reportData"
                :map-type="'cumulativeVisitsByDay'"
              />
            </div>
          </el-col>
        </el-row>

        <el-footer class="footer">
          <div class="footer-content">
            <img
              :src="logoURL"
              alt="Logo"
              class="logo"
              @error="setDefaultImage"
            >
          </div>
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>
import LineChart from '../graphs/VisitorFrequency.js';
import BarChart from '../graphs/VisitsByHour.js';
import DayBarChart from '../graphs/VisitsByDayOfWeek.js';
import CumulativeDayOfWeek from '../graphs/CumulativeDayOfWeek.js';
import ChartWithModal from '../customComponents/ChartWithModal.vue';
export default {
  components: {
    LineChart,
    BarChart,
    DayBarChart,
    CumulativeDayOfWeek,
    ChartWithModal,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hourOptions: [
        '12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM',
        '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM',
      ],
      selectedStartHour: '8AM',
      selectedEndHour: '5PM',
      componentKey: 0,

    };
  },
  computed: {
    logoURL() {
      return this.reportData?.logoURL ?? '/images/onspot-logo.svg';
    },
  },

  watch: {
    selectedStartHour() {
      this.refreshChart();
    },
    selectedEndHour() {
      this.refreshChart();
    },
    'reportData.reportconfigs': {
      deep: true,
      handler() {
        this.updateHoursFromReportData();
      },
    },
  },
  created() {
    this.updateHoursFromReportData();
  },
  mounted() {
  },
  methods: {
    refreshChart() {
      this.componentKey++;
    },
    updateHoursFromReportData() {
      if (this.reportData && this.reportData.reportconfigs) {
        if (this.reportData.reportconfigs.startHour) {
          this.selectedStartHour = this.reportData.reportconfigs.startHour;
        }
        if (this.reportData.reportconfigs.endHour) {
          this.selectedEndHour = this.reportData.reportconfigs.endHour;
        }
      }
    },
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },

};
</script>

  <style scoped>
  .wrapper {
    height: 100%;
    max-height: 100%;
  }
  .outer-container {
    height: 100%;
    max-height: 100%;
    width: 100%;
  }

  .header {
    background-color: white;
    padding: 0;
    margin-bottom: 10px; /* Gap between header and main container */

    height: 10%;
  }

  .header h1 {
    margin: 0;
  }

  .header-title,
  .header-description {
    margin-left: 40px; /* Margin away from the left of the screen */
  }

  .main-container {
    background-color: lightgrey;
    padding: 12px;
    height: 90%;
    justify-content: space-between;
    border-radius: 8px;
  }
  .el-row {
    background-color: lightgrey;
    margin-left: 0px !important;
    height: 44%;
  }

  .box {
    background-color: white;
    padding: 25px;
    /* height: 13em; */
    height: 80%;
    border-radius: 8px;
  }

  .map-box .column5-box {
    height: 90%; /* Or a calculated vh/vw, like calc(50vh - 50px) */

  }
  .second-row {
    /* margin-top: 20px; */
  }

  .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10% !important;
  position: relative;
}

.footer-content {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100px;
  max-height: 50px;
  height: auto;
  position: absolute;
  right: 45px;
}

.footer-text {
  margin: 0;
  color: gray;
  font-weight: bold;
  font-size: 20px;
}
  .box .data {
    font-size: 36px;
    font-weight: bold;
  }
  .box .title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box .description {
    font-size: 18px;
  }

  .box hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /* Media query for tablets */
  @media (max-width: 768px) {
    .el-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  /* Media query for phones */
  @media (max-width: 576px) {
    .el-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  </style>
