// BarChart.vue
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      default: 'Visits By Hour',
    },
    backgroundColor: {
      type: String,
      default: '#E99A3E',
    },
  },
  mounted() {
    // Group the data by 'group'
    const groupData = this.chartData.reduce((acc, item) => {
      if (!acc[item.group]) {
        acc[item.group] = { state: 0, federal: 0 };
      }
      if (item.attribute.includes('State')) {
        acc[item.group].state = item.headcount;
      } else if (item.attribute.includes('Federal')) {
        acc[item.group].federal = item.headcount;
      }
      return acc;
    }, {});

    // Extract labels and data for both datasets
    const labels = Object.keys(groupData).sort();
    const stateData = labels.map(label => groupData[label].state);
    const federalData = labels.map(label => groupData[label].federal);

    this.renderChart(
      {
        labels,
        datasets: [
          {
            label: 'State Donation Amount Percentile',
            data: stateData,
            backgroundColor: '#E99A3E', // Orange for state
          },
          {
            label: 'Federal Donation Amount Percentile',
            data: federalData,
            backgroundColor: '#6899D1', // Blue for federal
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
        },
        scales: {
          yAxes: [{
            gridLines: {
              drawOnChartArea: false,
            },
            ticks: {
              beginAtZero: true,

            },
          }],
          xAxes: [{
            gridLines: {
              drawOnChartArea: false,
            },
            categoryPercentage: 0.8,
            barPercentage: 0.9,
          }],
        },
        type: this.horizontal ? 'horizontalBar' : 'bar',
      },
    );
  },

};
