<template>
  <div
    style="height: 100%"
    @mouseleave="hover = false"
    @mouseenter="hover = true"
  >
    <span class="titleChart">    <el-tooltip
      :content="tooltip"
      :popper-options="{'width': '300px'}"
    >
      <i
        v-if="!hover"
        class="el-icon-info topRight"
        style="opacity:0; font-size: 18px;"
      />
      <i
        v-if="hover"
        class="el-icon-info topRight"
        style="font-size: 18px;"
      />
    </el-tooltip>{{ title }}     </span>
    <table
      v-if="!busy"
      style="width: 100%"
    >
      <tr>
        <th
          v-for="header in headers"
          :key="getUUID(header)"
        >
          <span
            class="header"
            style="margin-left: 5px"
            @click="sortTable(header.col)"
          >{{ header.label }}<span v-if="sortedState[header.col] === 'desc'">&#x25BC;</span><span v-if="sortedState[header.col] === 'asc'">&#9650;</span></span>
        </th>
      </tr>
      <tr
        v-for="(zip, idx) in paginatedSeries"
        :key="idx"
      >
        <td
          v-for="header in headers"
          :key="getUUID(header)"
          :width="header.col_size ? header.col_size : ''"
          :style="{'font-weight': '700',
                   'font-size': header.font_size ? header.font_size : '12px',
                   'background-color': (applyHeatedCol && header.col === heatedCol) ? `rgba(255, 165, 0, ${zip.opacity})`: '',
                   'text-align': header.text_align ? header.text_align : 'left','vertical-align': 'middle'}"
        >
          <span style="margin-left: 10px; margin-right: 5px">{{ zip[header.col].toLocaleString("en-US") }}</span> <progress
            v-if="progressBar && progressBarCol === header.col"
            :value="zip.perc_diff"
            max="100"
            style="display:inline-block; width: 40%;"
          />
        </td>
      </tr>
    </table>

    <div
      style=""
    >
      <el-pagination
        v-if="series"
        small
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="series.length"
        :pager-count="3"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import uuid from 'uuid';
import Progress from '../global/Progress.vue';

export default {
  components: { Progress },
  props: {
    title: {
      type: String,
      default: 'Unavailable',
    },
    series: {
      type: Array,
      default: () => [],
    },
    applyHeatedCol: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    heatedCol: {
      type: String,
      default: 'sum',
    },
    progressBar: {
      type: Boolean,
      default: false,
    },
    progressBarCol: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: 'Unavailable',
    },
  },
  data() {
    return {
      paginatedSeries: this.series.slice(0, this.pageSize),
      busy: false,
      sortedState: {},
      sortedSeries: this.series,
      pageNumber: 1,
      hover: false,
    };
  },
  methods: {
    getUUID() {
      return uuid();
    },
    handleCurrentChange(pageNumber) {
      this.paginatedSeries = this.sortedSeries.slice((pageNumber - 1) * this.pageSize, pageNumber * this.pageSize);
      this.pageNumber = pageNumber;
    },
    sortTable(header) {
      this.busy = true;

      const that = this;
      if (this.sortedState[header] === 'desc') {
        this.sortedState[header] = 'asc';
      } else {
        this.sortedState[header] = 'desc';
      }
      this.sortedSeries = this.sortedSeries.sort(function(a, b) {
        if (that.sortedState[header] === 'desc') {
          return a[header] > b[header] ? -1 : 1;
        }
        return  b[header] > a[header] ? -1 : 1;
      });

      this.handleCurrentChange(this.pageNumber);
      this.busy = false;
    },
  },
};
</script>

<style>
td {
}

.titleChart {
    font-family: Helvetica, Arial, sans-serif;
    text-anchor: start;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63);
    margin: 15px;
    margin-left: 10px;
    float: left !important;
    }

.card-style {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
    border: 1px solid #f0f0f0;
    border-top-color: rgb(240, 240, 240);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(240, 240, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(240, 240, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(240, 240, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    margin-left: 10px;
}
th {
  text-align: left;
  height: 4em;
}
 td {
  border-bottom: 1px solid rgba(240, 240, 240, 0.30000000000000004);
  border-collapse: collapse;
  line-height:30px;
}

table{
border-collapse: collapse;
border-spacing:0;
}

progress {
  accent-color: #509ee3;
}
</style>

<style scoped>
.header {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #74838f;
  font-size: 12px;
}

.header:hover {
    color: #509ee3;
    cursor: pointer;
}
</style>
